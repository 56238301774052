import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import About from './pages/about'
import NavancProductPage from './pages/navanc-product-page/navanc-product-page';
import ValleProductPage from './pages/valle-product-page/valle-product-page';
import NliteProductPage from './pages/nlite-product-page/nlite-product-page';
import ComingSoon from './pages/comingSoon'
import Advisors from './pages/advisors/advisors';
import BlogList from './pages/blogList';
import BlogPage from './pages/blogPage';
import TermsOfUse from './pages/termsOfUse';
import PrivacyPolicy from './pages/privacyPolicy';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="about" element={<About />} />
      <Route path="navanc" element={<NavancProductPage />} />
      <Route path="valle" element={<ValleProductPage />} />
      <Route path="n-lite" element={<NliteProductPage/>} />
      <Route path="advisors" element={<Advisors />} />
      <Route path="termsOfUse" element={<TermsOfUse />} />
      <Route path="privacyPolicy" element={<PrivacyPolicy />} />
      <Route path="blogPages" exact element={<BlogList />} />
      <Route path="blogPages/:fileType/:blogName" element={<BlogPage />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals