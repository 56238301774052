
import "./mouse-scroll.css";


const MouseScroll = () => {
    return (
        <div className="mousey">
            <div className="scroller"></div>
        </div>
    )
}

export default MouseScroll;
