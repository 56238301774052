import headturner from "../../assets/headturner.png";
import '../styles/headturner.css';
import navanc_logo from 'src/assets/navanc-logo-white.png';
import report from 'src/assets/report.png';
import NavBar from "../navbar";
import MouseScroll from "../mouse-scroll/mouse-scroll";


const HeadTurner = () => {
    return (
        <div id="head-turner">
            <NavBar transparent={true}></NavBar>
            <div className="head-turner-content">
                <div className="head-turner-headings">
                    <div className="head-turner-heading-container">
                        <div className="head-turner-heading-embellishment"></div>
                        <div className="head-turner-heading">
                            Score Assets'<br />Creditworthiness<br />Algorithmically.
                        </div>
                    </div>
                    <div className="head-turner-subheading">Underwrite Smartly.</div>
                </div>
                <img className="headturner-image" src={report} />
            </div>
            <div className="mouse-scroll-row">
                <div className="mouse-scroll">
                    <MouseScroll />
                </div>
            </div>
        </div>
    )
}

export default HeadTurner