import NavBar from '../components/navbar.js'
import Footer from '../components/footer'
import './styles/termsOfUse.css'

const TermsOfUse = () => {
    
    return(
        <>
            <NavBar />
            <div className='terms-of-use'>
                {
                   <>
                    <h1>Terms of Use</h1>
                    <p>TERMS OF USE - Valle
Last updated on 15th January 2024

These terms of use (“Terms of Use”) of Valle (“Product”) is entered into between Navanc Data Sciences Private Limited (“Navanc”) and the User describing the terms on which Navanc offers the User access to the Product and the information received from the User while accessing the services through website/URL/app of valle mobile app, www.valle-valuer.navanc.com, android application and iOS application (together referred to as the “Platform”). 
The access to the Product is offered to the User conditioned on acceptance of all provisions of this Terms of Use, along with Privacy Policy and any amendments made to it by Navanc and posted on the Platform. Accessing the Platform through any medium, including but not limited to accessing the Platform through mobile phones, smartphones, and tablets, are also subjected to this Terms of Use and Privacy Policy. At the time of registration, the User will be required to comply with this Terms of Use and Privacy Policy.
By (i) using this Product or any facility or service provided by this Platform in any way; or (ii) merely browsing the Product, the User agree that to have read, understood and agreed to be bound by this Terms of Use and/or Privacy Policy as applicable and available on the Platform. If the User do not agree to the foregoing, the User is prohibited from accessing or using the Product and/Platform.
PLEASE READ THE TERMS OF USE CAREFULLY BEFORE USING OR REGISTERING ON THE PLATFORM OR ACCESSING ANY MATERIAL, INFORMATION OR SERVICES THROUGH THE PLATFORM. THE USER USE OF THE PLATFORM AND THE PRODUCTS PROVIDED BY THE PLATFORM SHALL SIGNIFY THE ACCEPTANCE OF THIS TERMS OF USE AND TO BE LEGALLY BOUND BY THE SAME.
DEFINITIONS 
In this Terms of Use, unless the context or meaning otherwise requires: (a) terms defined by inclusion in quotations and/or parenthesis have the meanings so ascribed; (b) terms capitalized but not defined shall have the meaning ascribed to such term in the Privacy Policy and (c) the following terms shall have the meanings assigned to them herein below:
“User Account” shall have the meaning as ascribed to it in Clause 2.2 ;
“Applicable Law” means the laws and regulations, including all orders, rules, regulations, executive orders, decrees, policies, judicial decisions, notifications or other similar directives, each of the foregoing having the force of law, issued by any competent court or any government authority having jurisdiction over the matter in question;
“Branding Policy” means and refers to the branding guidelines mentioned under Clause 8 as amended from time to time in accordance with the provisions of this Terms and will include any other amendments made hereunder;
“Governmental Authorities” means the Government of India, state government and any ministry, department, board, agency, corporation, commission under the direct or indirect control of the Government of India, or any of the state governments, or both, commissions or tribunal or judicial or quasi-judicial body in the Republic of India;
“Intellectual Property” shall mean all rights and interests, vested arising out of any industrial or intellectual property, whether protected at common law or under statute, which includes (without limitation) any rights and interests in formats of inventions, copyrights, designs, trademarks, trade-names, know-how, business names, logos, processes, developments, licenses, trade secrets, goodwill, manufacturing techniques, specifications, patterns, drawings, computer software, technical information, research data, concepts, methods, procedures, designs and any other knowledge of any nature whatsoever throughout the world, and including all applications made for the aforesaid, rights to apply in future and any amendments/modifications, renewals, continuations and extensions in any state, country or jurisdiction and all other intellectual property rights whether available at this time and/or in future;
“Owner” shall mean the Person who has the legal and the rightful title of the Property;
“Person” means any individual, joint venture, company, corporation, body corporate, partnership (whether limited or unlimited), proprietorship, trust or any other enterprise (whether incorporated or not), Hindu undivided family, union, association, or any agency, department, Governmental Authority or any other legal entity;
“Property” shall mean the property that is being assessed for the purpose of generating the Valle Report; 
“Platform” shall mean the proprietary platform of Navanc available at valle, www.vall-valuer.navanc.com, and/or any other platform that is owned and operated by Navanc, which will host, transmit and/or make available products to end users; 
“Pricing Policy” shall have the meaning ascribed to it in Clause 6.1; 
“Privacy Policy” shall mean the privacy policy of Valle as fully described in Clause 16; 
“Product” shall mean Valle, which is available at playstore by the name Valle and https://valle-valuer.navanc.com; 
“Service Providers” shall mean the valuers and the lawyers who are prepare the Valle Report;
“Scope of Work” shall refer to the service offered by the Product as detailed under Clause 4;
“User” shall mean those who interact with the Product to access, or obtain Reports, and shall include those doing the same for personal, institutional, or business purposes;
“Valle Report” shall have the meaning ascribed to it in Clause 4.2; and
“Valle Report fee” shall mean the fee charged by Navanc for every Valle Report generated.
ELIGIBLITY OF USE 

The service offered is available to use for any User who has registered with the Product. 

Users will need to register and create a password-protected account (“User Account”).

The User by accessing the Product, confirms and warrants that the User is above the age of 18 (Eighteen) and acknowledges that the Product offered by the Platform is intended solely for such Users who are 18 (Eighteen) years of age or older and who can form legally binding contracts under Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Product offered by the Platform. 
USE OF PLATFORM AND OBLIGATIONS

Subject to compliance with the Terms of Use, Navanc grants the User a non-exclusive, limited license to access and use this Product for generating the Report. This license does not include amongst others any downloading or copying of any information for the benefit of another user or any third party. The User may not bypass any measures used by the Platform to prevent or restrict access to the Platform. Any unauthorized use by the User shall terminate the permission or license granted to the User by the Platform.
The User agree to use the Platform and the Product offered on the Platform only for purposes that are permitted by (a) this Terms of Use; (b) Privacy Policy and (c) any Applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions.
The User hereby undertakes not to: 
take any action that imposes or may impose (as determined by Navanc’s sole discretion) an unreasonable or disproportionately large load on Navanc‘s or Navanc ‘s third party providers’ infrastructure;
knowingly interfere or attempt to interfere with the proper working of Navanc providing the Products;
share any details required for generating the Valle Report in instances where the User and the Owner are different individuals, without the Owner's explicit consent.
bypass, circumvent, or attempt to bypass or circumvent, any measures Navanc may use to prevent or restrict access to Valle Reports, including without limitation other accounts, computer systems or networks connected to them;
copy, modify, host, stream, sublicense, or resell Valle Reports;
knowingly enable or allow unauthorized users to access the Product using the User Account;
access or attempt to access the Product by any means other than the interface Navanc provides or authorizes;
reverse engineer or access the Product with the purpose to (i) build a competitive software product providing a similar service, (ii) build a Product using similar ideas, features, functions or graphics of the Product, or (iii) copy any ideas, features, functions or graphics of the Product;
frame or mirror any part or content of the Platform or the Product;
knowingly use or share content or engage in behavior that violates any party’s intellectual property rights;
impersonate any Person, or falsely state or otherwise misrepresent the User’s affiliation with a Person;
knowingly introduce into the Platform or the Product any software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware;
knowingly manipulate identifiers in order to disguise the origin of any of User content; and
violate any applicable terms and conditions to the products and services, including this agreement, or the applicable laws.
The User shall comply with any codes of conduct, policies, or other notices Navanc provides or publishes in connection with the Product in their Platform. The User shall promptly notify Navanc if it becomes aware of any security breach related to the Product.
The User agree that it is solely responsible to Navanc for any breach of the obligations under this Terms of Use and for the consequences (including any loss or damage which Navanc or its affiliates or its users or any third party may suffer) of any such breach.
In the event that any content, information and/or transaction on the Platform which is in violation of this Terms of Use and/or Applicable law comes to knowledge of the User and the User shall take all steps to inform the Grievance Officer.


The User shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and rules made there under as applicable and as amended from time to time and also all applicable domestic laws, rules and regulations (and regarding the use of the Platform and Products. The User shall not engage in any transaction, which is prohibited by the provisions of any Applicable law.


The User shall not, either alone or in conjunction with other users, manipulate or attempt to manipulate the prices of any services being provided through the Platform by Navanc.


The User hereby acknowledge and agree that Navanc shall not be liable for submitted content or the defamatory, offensive, or illegal conduct of the User, any user or third party and that the risk of harm or damage from the same rests entirely with the User.

The User is responsible for maintaining the confidentiality of the User’s account access information and password, if the User is registered on the Platform. The User shall be responsible for all usage of the User’s account and password, whether or not authorized by the User. The User shall immediately notify Navanc of any actual or suspected unauthorized use of the User’s account or password. 

If a User provides any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or Navanc has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Navanc has the right to discontinue the services to the User at its sole discretion.

The User agrees and acknowledges that it shall be the User’s sole responsibility to obtain prior consent of the Owner and shall have the right to share, upload and publish any sensitive personal information of the Owner with respect to the Valle Report. Navanc assumes no responsibility for any claim, dispute or liability arising in this regard, and the User shall indemnify Navanc and its officers against any such claim or liability arising out of unauthorized use of such information.

The User understands and agrees that Navanc will not be liable for:
User interactions and associated issues User has with the Service Provider;
any misconduct or inappropriate behaviour by the Service Provider; and
for any content, fact, infromation or the language used in the Valle Report whatsoever. The Service Provider is solely responsible and liable for Valle Report and any information provided to Navanc including but not limited to the content in them.
SCOPE OF WORK
Valle’s platform acts as an intermediary connecting the User with the Service Providers for the requirement of generating the Valle Report. 

The report generated (“Valle Report”) will contain the professional observations of the Service Providers after examining the Property and the information provided by the User. 

The Scope of Work of Navanc is limited to connecting the User with the Service Provider. However, Navanc may by its services, collect redacted information relating to the Valle Report for the sole purpose of analysis, data science, and AI activities. The redacted collected information will not involve any analysis on the Owner and will be used only for improving the quality of Navanc’s services and to build new services.
INTERNATIONAL USE
The Platform and the services offered through the Platform are intended for residents of India only and the contents of the Platform are subject to the matters set out under this Terms of Use, which are to be read in conjunction with the Privacy Policy. The Users agree to comply with all Applicable Laws and rules including but not limited to rules regarding online conduct and acceptable content.
PAYMENT GUIDELINES

Navanc, for each Valle Report generated, shall raise an invoice to the User on a monthly basis.
 
The User shall pay the Valle Report Fee to Navanc for the provision of the Product and the use of Platform, in accordance with the terms as set out it in the Pricing Policy.  

Navanc hereby undertakes and confirms that no financial data of the User will be stored or received by Navanc. 

The Valle Report Fees are exclusive of goods and services tax and/or other applicable taxes. All payments to Navanc under this Terms of Use will be subject to deduction of taxes, as per the Applicable Laws and accordingly net amount after deduction of tax shall be paid to Navanc.

Failure to pay the applicable Valle Report Fee may result in User’s access to the Valle Report. 
TERMINATION

Navanc reserve the right to terminate or suspend access to the Product without prior notice, if the User violate the terms of this Terms of Use.

Notwithstanding anything, nothing in this Terms of Use shall in any manner prejudice the rights of the User and/or Navanc arising either under this Terms of Use or under Applicable Law.
BRANDING POLICY

Each Valle Report generated through the usage of the Product, shall bear the official branding, logo, or identifier of Navanc. 

The Users acknowledge and agree that these branding elements are an integral part of the Valle Report and signify their origination from Navanc. 

The Users undertake not to modify, alter, remove, or obscure the branding, logo, or any identification associated with Navanc on the Valle Reports. The Valle Report are intended for the sole purpose of property valuation and internal use by the Users. 

Any unauthorized use, modification, or removal of the branding or associated identifiers from the Valle Reports generated by Navanc constitutes a violation of this Terms of Use and may result in termination of access to the platform.
UPDATES

Navanc reserves the right to improve or otherwise modify the Product and its system architecture at any time, provided that any such modification does not materially diminish the core functionality of the Product.

Navanc reserves the right to change or modify this Terms of Use at its sole discretion at any time by providing prior intimation to the User. However, it is clarified that no such intimation will be required in case of change or modification to this Terms of Use in compliance with Applicable Laws. 

Navanc shall notify the User in case of any material changes to this Terms of Use or to any service or other feature of the Product and/or Platform. 
INTELLECTUAL PROPERTY RIGHTS 

Navanc shall retain all rights, titles and interests, including without limitation all the Intellectual Property, including, but not limited to, all trademarks, logos, software, technology, information, content, materials, guidelines and documentation. The User hereby agree that all rights, title and interest in and to the Platform and/or the Product will remain with and belong exclusively to Navanc at all times. Navanc shall have a royalty-free, worldwide, transferable, sub-licensable, irrevocable and perpetual license to incorporate into the Platform and/or the Product, or otherwise use any suggestions, enhancement requests, recommendations or other feedback it receives from the User.

The User undertakes and agrees that, except as expressly provided herein, this Terms of Use does not convey any additional rights in the Platform and/or the Product, or in any Intellectual Property rights associated therewith. 

Navanc retains all right, title, and interest in all the reports, analysis, and statistics generated from the User’s use of the Platform, and Navanc shall have the right to use such reports for purposes of providing, maintaining, developing, and improving its Platform. To the extent of such reports include Personal Data, Navanc will store such data in anonymized form. 

Unless otherwise indicated or anything contained to the contrary or any proprietary material owned by a third party and so expressly mentioned, Navanc owns, solely and exclusively, all Intellectual Property rights in and to the trademark “Navanc” and the Platform, including, without limitation, any and all rights, title and interest in and to copyright, related rights, patents, utility models, designs, know-how, trade secrets and inventions (patent pending), goodwill, source code, meta tags, databases, text, content, graphics, icons, and hyperlinks.

Any commercial or promotional distribution, publishing or exploitation of the Product, or any content, code, data or materials on the Product, is strictly prohibited unless the User have received the express prior written permission from authorized personnel of Navanc or the otherwise applicable rights holder. Other than as expressly allowed herein, the User shall not download, post, display, publish, copy, reproduce, distribute, transmit, modify, perform, broadcast, transfer, create derivative works from, sell or otherwise exploit any content, code, data or materials on or available through the Product. It is further agreed that the User shall not alter, edit, delete, remove, otherwise change the meaning or appearance of, or repurpose, any of the content, code, data, or other materials on or available through the Product, including, without limitation, the alteration or removal of any trademarks, trade names, logos, service marks, or any other proprietary content or proprietary rights notices. It is acknowledged that the User do not acquire any ownership rights by downloading any copyrighted material from or through the Platform. If the User make other use of the Product, or the content, code, data or materials thereon or available through the Product, except as otherwise provided above, the User may violate copyright and other laws of India, other countries, as well as applicable state laws and may be subject to liability for such unauthorized use.

Any rights not expressly granted herein, are reserved to Navanc. The User agree to abide by all copyright notices, information, or restrictions contained in any part of the Product. The User must not alter, delete, or conceal any copyright, trademark, patent, or other notices contained on the Platform and/or Product.
THIRD PARTY SERVICES

Navanc reserves the right to utilize third-party content sources for the collection of raw data necessary to establish and enhance its proprietary dataset. However, Navanc confirms to not to disclose, transfer, or share any User data or personally identifiable information with these third-party websites or entities engaged in data collection processes.
LINKING TO THIRD PARTY WEBSITES 

The User may be able to link from the Product to third party websites and third party websites may link to the Product (“Third Party Websites”). The User acknowledge and agree that Navanc has no responsibility for the information, content, products, services, advertising, code or other materials which may or may not be provided by or through Third Party Websites, even if they are owned or run by affiliates of Navanc.

The User acknowledges that any information provided to any third party or usage of Third Party Websites while using the Product, Terms of Use by Navanc will no longer apply and that Navanc is not responsible for the practices of these Third Party Websites.

Links to Third Party Websites do not constitute an endorsement or sponsorship by the Navanc of such websites or the information, content, products, services, advertising, code or other materials presented on or through such websites.

The User is not permitted to link directly to any image hosted on the Platform or the Navanc’s Services, such as using a “in-line” linking method to cause the image hosted by the Navanc to be displayed on another website.

The User agree not to download or use images hosted on the Platform or Third Party Websites, for any purpose, including, without limitation, posting such images on another site.

Navanc reserves all of Navanc’s rights under the law to insist that any link to the Platform be discontinued, and to revoke the User’s right to link to the Platform from any other website at any time upon written notice to the User.
WARRANTY AND LIABILITIES

The Platform, Product, and other materials are provided by Navanc on an “as is” basis without warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title, non-infringement, merchantability or fitness for a particular purpose. Without limiting the foregoing, Navanc makes no warranty that:

the Platform or the Product will meet the User’s requirements or the use of the Platform or the Product will be uninterrupted, timely, secure or error-free at all times the User acknowledges that the Product and Platform may be subject to limitations, delays and other problems inherent in the use of such communications facilities ; 
the results that may be obtained from the use of the Platform, Product or other materials will be effective, accurate or reliable; 
the quality of the Platform, Product or other materials will meet expectations of the User; or 
that any errors or defects in the Platform, Product or other materials will be corrected. 
No advice or information, whether oral or written, obtained by the User from Navanc or through or from use of the Platform and/or Product shall create any warranty not expressly stated in the Terms of Use.

To the maximum extent permitted by applicable law, Navanc will have no liability related to User content arising under Intellectual Property rights, libel, privacy, publicity, obscenity or other laws. Navanc also disclaims all liability with respect to the misuse, loss, modification or unavailability of any user content.

Navanc has endeavoured to ensure that all the information on the Platform is correct, but Navanc neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information, product or service. Further, Navanc shall not be held responsible for non-availability of the Platform and/or Product during periodic maintenance operations or any unplanned suspension of access to the Platform that may occur due to technical reasons or for any reason beyond the Navanc’s control. 

User understands and agrees that any material or data downloaded or otherwise obtained through the Platform is done entirely at their own discretion and risk and the User’s will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material or data. Navanc accepts no liability for any errors or omissions, with respect to any information provided to the User whether on behalf of itself or third parties.

Navanc shall not be responsible in any manner for the authenticity of the personal information or sensitive personal data or information supplied by the Owner to User and/or User to Navanc.
INDEMNITY

Indemnity by Navanc: Navanc hereby undertakes and agrees that Navanc shall indemnify and keep the User fully indemnified from and against all direct, proven and actual losses, damages, liabilities, deficiencies, actions, judgments, interest, awards, penalties, fines, costs, claims or expenses of whatsoever kind, including reasonable attorneys’ fees, arising out of or resulting directly as a result of any third party claims  arising out of a breach of Intellectual Property rights provided, including the events mentioned under Clause 14.3, under this Terms of Use, solely attributable to Navanc. 

Indemnity by the User: The User hereby undertakes and agrees that the User shall indemnify and keep Navanc, its officers, directors, and  employees, fully indemnified from and against all losses, damages, liabilities, deficiencies, actions, judgments, interest, awards, penalties, fines, costs, claims or expenses of whatsoever kind (by any Governmental Authority and/or the third parties), including reasonable attorneys’ fees, arising out of or resulting directly as a result of gross negligence, misrepresentations or breach of any obligations, representations and/or warranties provided, including the events mentioned under Clause 14.3, under this Terms of Use. 

Indemnity Events:

Breach of Warranty: Any breach of the warranties or representations made by the Parties under this Terms of Use.
Breach of Branding Policy: Any breach of the Branding Policy of Navanc under this Terms of Use.
Intellectual Property Infringement: 
Any violation of any Navanc’s rights, titles and interests, including without limitation all the Intellectual Property, including, but not limited to, all trademarks, logos, software, technology, information, content, materials, guidelines and documentation;
Any violation of any Navanc’s right, title, and interest in all the reports, analysis, and statistics generated from the User’s use of the Product;
Unauthorised usage of any Intellectual Property rights in and to the trademark “Navanc” and the Product, including, without limitation, any and all rights, title and interest in and to copyright, related rights, patents, utility models, designs, know-how, trade secrets and inventions (patent pending), goodwill, source code, meta tags, databases, text, content, graphics, icons, and hyperlinks
Any violation of any third party right, including without limitation, any copyright, property, or privacy right; 
Any commercial or promotional distribution, publishing or exploitation of the Product, or any content, code, data or materials on the Product;
Unauthorised downloading, posting, displaying publishing, copying, reproducing, distributing, transmitting, modifying, performing, broadcasting, transferring, creating derivative works from, selling or otherwise exploiting any content, code, data or materials on or available through the Product.

Data Security Breach: 


Any data breach or unauthorized access of personal data to the extent caused by the negligence or breach of data security obligations by either Parties;
Any data breach or unauthorized sharing of data without the consent of the Owner; 
Uploading or distributing files that contain software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of the Product or any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate Product or any system, data or personal information
Uploading any form of Sensitive Personal Data Information as defined in accordance with the Applicable Law, banking identification details, credit card, debit card or payment card information subject to the Payment Card Industry Data Security Standards or equivalent standards, government identification including but not limited to passport information, visas, driving license and/or any other forms of identification documentations.


Non-Compliance: Any claim arising from failure to comply with Applicable laws, Terms of Use, Privacy Policy, or industry standards related to the Platform and/or Product. 

User hereby undertakes to specifically indemnify and keep Navanc fully indemnified against all losses, damages, liabilities, deficiencies, actions, judgments, interest, awards, penalties, fines, costs, claims or expenses of whatsoever kind (by any Governmental Authorities and/or the third parties), including reasonable attorneys’ fees, arising out of breach of its obligations under this Terms of Use;

The indemnification obligations of as defined above shall be without prejudice to the rights and remedies of either Parties under Applicable Law, including without limitation, claiming appropriate compensation or damages from and/or termination of this Terms of Use or any part thereof, in accordance with the terms and conditions of this Terms of Use.
VIOLATION OF THE TERMS OF USE

Navanc may at any time at its sole discretion, with prior intimation to the User, refuse access, restrict, suspended or terminate any User and block such User from visiting the Platform and/or Product. A User that has been suspended or blocked may not register or attempt to register with Navanc use the Platform in any manner whatsoever until such time that such user is reinstated by Navanc or as mutually agreed upon by the Parties.

Notwithstanding the foregoing, if the User breach the Terms of Use, Navanc reserves the right to recover any amounts due and owing by the User to the Navanc and to take strict legal action including but not limited to initiate criminal or other proceedings against the User or obtaining any injunctive or equitable relief that Navanc deems necessary or appropriate in such circumstances. These remedies are in addition to any other remedies Navanc may have against the User in law or in equity.
PRIVACY POLICY 

The User acknowledges and accepts the terms of the Privacy Policy of the Product located at [https://www.navanc.com/privacyPolicy] in relation to collection, use, processing, storage, retrieving, disclosure, or transfer of information, including personal information and sensitive personal data or information that Navanc may receive through User’s access, interaction or use of the Platform (“Privacy Policy”).

The collection, use, processing, storage, retrieving, disclosure, or transfer of information, including personal information and sensitive personal data or information by Navanc will be in accordance with the Privacy Policy and as required or permitted under Applicable Laws.

The User further acknowledges that the User has read, comprehensively reviewed, and fully understands the contents and terms of the Privacy Policy.

The User hereby consents and agrees that the terms and contents of the Privacy Policy, as provided by Navanc, are acceptable to the User.

The Privacy Policy is incorporated by reference into this Terms of Use, and any violation thereof may result in the enforcement of remedies and legal actions as specified in the Privacy Policy and Applicable laws.


The User shall continue to comply with the Privacy Policy while utilizing the Platform, and any updates or modifications thereto.
GOVERNING LAW AND DISPUTE RESOLUTION

Governing Law: These Terms of Use and any disputes arising out of or related to these terms or the use of the Platform and/or Product service shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.

Arbitration: In the event of any dispute between the Parties arising out of or relating to this Terms of Use, the Parties agree that provisions of Section 29B of the Arbitration and Conciliation Act, 1996 will be applicable.

Exceptions: Notwithstanding the above, either party may seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the unauthorized use or disclosure of Intellectual Property, confidential information, or to address any other urgent matter that requires immediate legal action.
GRIEVANCE REDRESSAL

In case of any concern/grievance with respect to the contents on the Platform/Product and/or if there has been violation of this Terms of Use and/or the Privacy Policy, the User may choose to seek redressal by writing to Us at [grievanceofficer@navanc.com] or contacting us at  
In compliance of the Information Technology Act, 2000 and rules made thereunder and also in compliance of the Consumer Protection (E-Commerce) Rules, 2020 the name and contact details of the Grievance Officer are herein as under:
Name: Byom Kesh Jha & Nagachethan S M
Designation: Co-Founders, Director
Address: Awfis, Renaissance Centra, 27 & 27/1, Mission Rd, Sampangiram Nagar, Bengaluru, Karnataka-560027, India
Email: grievanceofficer@navanc.com
Phone: 8792957062
In compliance with the Consumer Protection Act 2019, Navanc has appointed a Nodal Officer who can be contacted in case of any consumer grievances or queries. The name and email ID of the Nodal Officer are as follows:
Nodal Officer: Byom Kesh Jha & Nagachethan S M
Email ID: grievanceofficer@navanc.com
RETENTION AND REMOVAL
Navanc may retain such information collected from Users or Valle Report for a period of 07 (Seven) years for audit and collection purposes. Navanc may retain redacted information for as long as necessary depending on the type of information; purpose, means and modes of usage of such information; and according to the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011. Computer web server logs may be preserved as long as administratively necessary. 
EXCLUSIVITY
This Terms of Use shall be non-exclusive for Navanc and it shall, inclusive of its affiliates, subsidiaries and divisions, be free to pursue other agreements or collaborations of any kind offering similar Product to any other party, inclusive of competing entities to the User. 
SURVIVAL
Notwithstanding anything mentioned in this Terms of Use, the clauses stipulating Governing Law and Dispute Resolution (Clause 17) and Indemnity (Clause 14) shall survive the termination of this Terms of Use.
WAIVER
No waiver by either Party of any breach of any of the conditions, covenants or this Terms of Use and/or Privacy Policy shall be effective unless it is in writing and no failure or delay by either Party in insisting upon strict performance of any of the terms or conditions of this Terms of Use and/or Privacy Policy or in exercising any right, power or privilege hereunder shall operate as a waiver thereof.
SEVERABILITY

If, for any reason, a court of competent jurisdiction finds any provision of these Terms of Use and/ or Privacy Policy or any part thereof is held to be invalid, illegal, or unenforceable for any reason, such invalidity, illegality, or unenforceability shall not affect the remaining provisions of these Terms of Use and/or Privacy Policy, and they shall remain in full force and effect. To the fullest extent permitted by applicable law, the invalid, illegal, or unenforceable provision shall be replaced with a valid, legal, and enforceable provision that best reflects the parties' intent and the original purpose of the provision.
This Terms of Use is an electronic record in terms of the Information Technology Act, 2000 and rules and amendments made thereunder. Further, this electronic record is generated by a computer system and does not require any physical or digital signatures. This electronic record is published in accordance with the relevant provisions of the Information Technology Act, 2000 and rules and amendments made thereunder.



</p>
                   </>
                }
            </div>
            <Footer />
        </>
    )
}

export default TermsOfUse