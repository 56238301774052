
import React, { useLayoutEffect, useState, useEffect } from 'react';
import './scroll-reveal.css'


const ScrollReveal = (props) => {
    var [scrollPosition, setScrollPosition] = useState(0);
    var [windowWidth, setWindowWidth] = useState(1920);
    const animatrix= props.animationMatix
    console.log(props.id)
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleWindowResize)
        handleWindowResize()
        return () => window.removeEventListener('resize', handleWindowResize);
    },[])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const assignTop = (id) => {
        if(windowWidth > 1000){
            return animatrix[id]["1000px"].top
        }
        else if(windowWidth > 600){
            return animatrix[id]["600px"].top
        }
        else if(windowWidth > 250){
            return animatrix[id]["250px"].top
        }
    }
    const assignLeft = (id) => {
        if(windowWidth > 1000){
            return animatrix[id]["1000px"].left
        }
        if(windowWidth > 600){
            return animatrix[id]["600px"].left
        }
        if(windowWidth > 250){
            return animatrix[id]["250px"].left
        }
    }
    const assignStart = (id) => {
        if(windowWidth > 1000){
            return animatrix[id]["1000px"].start
        } 
        else if(windowWidth > 600){
            return animatrix[id]["600px"].start
        }
        else if(windowWidth > 250){
            return animatrix[id]["250px"].start
        }
    }
    const assignDuration = (id) => {
        if(windowWidth > 1000){
            return animatrix[id]["1000px"].duration
        } 
        else if(windowWidth > 600){
            return animatrix[id]["600px"].duration
        }
        else if(windowWidth > 250){
            return animatrix[id]["250px"].duration
        }
    }

    const interpolate = (current, start, end) => {

        var value = 0;
        var display = 'None';
        var range = end - start;
        var fade_range = 500;
        var start_window_end = fade_range + start;
        var ending_window_start = end - fade_range;
        

        if ((start < current) && (current < start_window_end)) {
            value = (current - start) / fade_range;
            display = 'block'
        }
        if ((start_window_end < current) && (current < ending_window_start)) {
            value = 1; 
            display = 'block'
        }
        if ((ending_window_start < current) && (current < end)) {
            value = ((end - current) / fade_range);
            display = 'block'
        }
        return [value, display]
    }

    var [opacity, display] = interpolate(scrollPosition, assignStart(props.id), assignStart(props.id) + assignDuration(props.id));
    const style = {
        'top': assignTop(props.id),
        'left': assignLeft(props.id),
        'opacity': opacity,
        'display': display,
    }
    return(
        <div className='scroll-reveal-container' style={style}>
            {props.children}
        </div>
    );
};

export default ScrollReveal
