import PersonCard from "src/components/about/person-card/personCardV2";
import ved_image from "src/assets/people/ved.jpg";
import naga_image from "src/assets/people/naga.jpg";
import mounika_image from "src/assets/people/mounika.jpg";
import bhyom_image from "src/assets/people/bhyom.jpg";
import vedant_image from "src/assets/people/vedant.jpg";
import jai_image from "src/assets/people/jai.jpg";
import sunil_image from "src/assets/people/sunil.jpg";
import nitin_image from "src/assets/people/nitin.png";
import sachin_image from "src/assets/people/Sachin.png";
import akanshu_image from "src/assets/people/Akanshu.png";
import rishabh_image from "src/assets/people/Rishabh.png";
import mayur_image from "src/assets/people/mayur.png";
import manik_image from "src/assets/people/Manik.jpg";

import "./people-container.css";

const founder = [
  {
    image: naga_image,
    person_name: "Nagachethan S M",
    designation: "Founder and Chief Executive Officer",
    person_description:
      "A seasoned banker with more than a decade of experience with reputed banks and NBFCs. Has done deep work on product process and design thinking with customer centricity. Naga is a graduate of IRMA and holds an Engineering Degree. He is a Carnatic Vocalist and is interested in Sanskrit Literature.",
    linkedin_link: "https://www.linkedin.com/in/nagachethan-s-m-ab8bb26b/",
  },
  {
    image: bhyom_image,
    person_name: "Byom Kesh Jha",
    designation: "Co-Founder and Chief Technology & Data Officer",
    person_description:
      "Data Wanderer. Proven ability to design and develop solutions and tools based on predictive modeling in multiple domains especially in retail & education. Sound Knowledge of multiple NLP techniques, Sequence Learners - NER’s, Classifiers, Building Knowledge Base, Deep Learning, Product Aspect Extraction, User-generated content analysis.",
    linkedin_link: "https://www.linkedin.com/in/byomkeshjha/",
  },
];
const founding_team = [
  {
    image: ved_image,
    person_name: "Ved Mathai",
    designation: "Technology Consultant",
    person_description:
      "A deep tech mind with hands on experience of solving problems for public good. Has a keen sense of business and customer centricity. He has deep interests in History and Philosophy.",
    linkedin_link: "https://www.linkedin.com/in/vedmathai/",
  },
  {
    image: nitin_image,
    person_name: "Nitin Zagade",
    designation: "Lead Partnerships and Founding Team Member",
    person_description:
      "Accomplished in the mortgage sector, skilled in loan underwriting, Risk assessment, and product innovation. Profound insights and dedication to excellence, drive my contributions for lasting industry success. At Navanc, I oversee partnerships and hold a founding team member role, contributing to our dynamic growth.",
    linkedin_link: "https://www.linkedin.com/in/nitin-zagade",
  }
];

const team = [
  {
    image: sunil_image,
    person_name: "Sunil Kumar Rai",
    designation: "Manager - Operations",
    person_description:
      "Intense, focussed and goal oriented professional. A lawyer and economist by academics and ex banker by profession who understands at the core of nuances and operational mechanism of credit worthiness of housing and land property.",
    linkedin_link: "",
  },
  {
    image: mounika_image,
    person_name: "Mownika",
    designation: "UI/UX Designer",
    person_description:
      "An innovative designer who had a gold medal in visual communication degree and she has worked as an artist, a photographess, an editor and a 3D animator.Later ventureed into edu tech industry as graphic designer worked on creating workbook for kids, who Love to communicate visually and add interests through a visual content.",
    linkedin_link: "https://www.linkedin.com/in/mownika-r-201058206",
  },
  {
    image: sachin_image,
    person_name: "Sachin KR",
    designation: "Assocaite Manager",
    person_description:
      "An associate manager in sales and operations management is responsible for supporting the sales department with operational tasks, such as managing the CRM system, creating reports, analyzing data, and developing strategies to improve sales. They also oversee the sales staff and handle customer complaints.",
  },
  {
    image: akanshu_image,
    person_name: "Akanshu Sonkar",
    designation: "Machine Learning Engineer",
    person_description:
      "Data Evangelist, adept at wielding colossal datasets and spearheading data pre processing, analysis, cleansing, and cutting-edge feature engineering. Proficient in Python, libraries, and cloud platforms. A visionary of AI, Deep Learning, and Computer Vision, sculpting data into captivating tales. Always hungry for new horizons, relentless learner, seizing every opportunity to soar higher.",
    linkedin_link: " https://www.linkedin.com/in/akanshu11121/",
  },
  {
    image: rishabh_image,
    person_name: "Rishabh Dewang",
    designation: "Software Developer Engineer - 1",
    person_description:
      "Tech-Enthusiast, Passionate about technology and driven to solve complex challenges, I am a dedicated software developer at a leading fintech company. My unwavering commitment to continual growth fuels my pursuit of cutting-edge skills and knowledge. I thrive in an environment where innovation is paramount and take pride in contributing to the evolution of technology.",
    linkedin_link: "https://www.linkedin.com/in/rishabh-dewang-3b83a0101/",
  },
  {
    image: mayur_image,
    person_name: "Mayur Jain",
    designation: "Senior Full Stack Developer",
    person_description:
      "I specialize in developing versatile applications that seamlessly integrate established methodologies with contemporary insights. My diverse expertise enables me to navigate complex challenges and deliver impactful solutions. Committed to excellence, I harness the latest innovations to craft solutions that resonate with users and elevate industry standards.",
    linkedin_link: "https://www.linkedin.com/in/mayur-jain-899284115",
  },
  {
    image: manik_image,
    person_name: "Manik Salagar",
    designation: "Associate Deputy Manager - Operation",
    person_description: 
      "Operation team collaborates seamlessly to execute tasks, utilizing diverse skills and efficient communication. The  synergy enhances productivity, problem-solving, and project success.",
    linkedin_link: "https://www.linkedin.com/in/manikya-salgar-02079827a",
  },
];

const PeopleContainer = (props) => {
  const founding_team_cards = founding_team.map((item) => {
    return (
      <PersonCard
        person_image_src={item?.image}
        person_name={item?.person_name}
        designation={item?.designation}
        person_description={item?.person_description}
        linkedin_link={item?.linkedin_link}
      />
    );
  });
  const founder_cards = founder.map((item) => {
    return (
      <PersonCard
        person_image_src={item?.image}
        person_name={item?.person_name}
        designation={item?.designation}
        person_description={item?.person_description}
        linkedin_link={item?.linkedin_link}
      />
    );
  });
  const team_cards = team.map((item) => {
    return (
      <PersonCard
        person_image_src={item?.image}
        person_name={item?.person_name}
        designation={item?.designation}
        person_description={item?.person_description}
        linkedin_link={item?.linkedin_link}
      />
    );
  });
  return (
    <div class="people-container">
      <div className="founders-outer-container">
        <div className=" people-container-header">
          <div className="people-left-triangle"></div>
          <h1 className="people-founder-heading">Founders</h1>
        </div>
        <div className="founder-inner-container">{founder_cards}</div>
      </div>
      <div className="people-container-header">
        <div className="people-left-triangle"></div>
        <h1 className="people-founding-heading">The Founding Team</h1>
      </div>
      <div className="founding-container">{founding_team_cards}</div>
      <div className="people-container-header">
        <div className="people-left-triangle"></div>
        <h1 className="people-team-heading">Team</h1>
      </div>
      <div className="team-container">{team_cards}</div>
    </div>
  );
};

export default PeopleContainer;
