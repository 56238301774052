import NavBar from '../components/navbar.js'
import Footer from '../components/footer'
import './styles/privacyPolicy.css'

const PrivacyPolicy = () => {
    
    return(
        <>
            <NavBar />
            <div className='privacy-policy'>
                {
                   <>
                    <h1>Valle's Privacy Policy</h1>
                    <p>PRIVACY POLICY- Valle

Last updated on 15th January 2024

THE POLICY

‘Valle,’ as created and developed by Navanc Data Sciences Private Limited (“Navanc”, “We”, “Us”, “Our”) is a platform to streamline legal and valuation services by connecting a diverse range of lawyers and valuers, spanning geographical tiers. Through our platform, we minimize document logistics, expedite loan processes, benefitting both individuals and financial institutions (“Users”) through connecting the lawyers and valuers (“Service Providers”) with the Users (as elaborated in the Terms of Use located at [https://www.navanc.com/termsofuse]) (“Services”).This privacy policy (“Policy”) discloses the practices and policies adopted by Navanc for collecting, storing, retrieving, or using Personal Information (as defined hereunder) it may receive from its users (“You”, “Your”, “User”) who access the Platform (defined below) to receive Services. The information received from You while accessing the Services through website/URL of valle-valuer.navanc.com & valle mobile app, android application and iOS application (together referred to as the “Platform”) will be utilised in a manner as set out in this Policy.

All terms and conditions regarding usage of the Platform are incorporated in this Policy by reference. The Policy seeks to comply with the provisions of the Information Technology Act, 2000, the Information Technology (Reasonable security practices, procedures and sensitive personal data or information) Rules, 2011, and Digital Personal Data Protection Act, 2023, and rules made thereunder, as may be amended from time to time (“IT Laws”) that require publishing of the Policy for collection, use, storage and transfer of sensitive personal data or information.

Please read this Policy carefully to make sure You understand how any Personal Information You provide to Us will be handled. If You use any of Our Services, You will be expressly regarded as having read and accepted this Policy. By mere use of this Platform, You expressly consent to Our use and disclosure of Your Personal Information in accordance with this Policy. However, this policy does not apply to those affiliates and partners that have their own privacy policy. In such situations, We recommend that you read the privacy policy on the applicable site.

Navanc is committed to ensuring that Your privacy is protected. This Policy is provided to explain Our policies and practices regarding the collection, use, and disclosure of information about You. Please read the following statement to learn about Our information gathering and dissemination practices.

In the course of using this Platform or availing the Services offered on the Platform, Navanc and its affiliates may become privy to the Personal Information of the Users, including information that is of a confidential nature.

We are strongly committed to protecting Your privacy and have taken necessary and reasonable measures to protect the confidentiality of Your information. Navanc shall not be held liable for disclosure of confidential information/Your Personal Information when such disclosure is in accordance with this Policy and/or necessitated by applicable law. This Policy describes how We handle Your Personal Information for Our Services on the Platform.

INFORMATION WE COLLECT 

We aim to collect, use and disclose only such information as is required to enable us to manage your account, to provide the Services, to maintain our customer/visitor lists, to respond to your inquiries or provide feedback, for identification and authentication purposes, for service improvement. We will maintain the confidentiality of any human identifiable information you provide to us on signing up for Our Services or contacting us with questions or for further information and We will use it only for the purposes for which We have collected it (subject to the exclusions and disclosures We have listed below).

All the information provided to Navanc by you, including Personal Information or any Sensitive Personal Data or Information, is voluntary. You understand that Navanc may use certain information of yours, which has been designated as Personal Information or ‘Sensitive Personal Data or Information’ under the IT Laws, (a) for the purpose of providing you Our Services, (b) for commercial purposes and in an aggregated or non-personally identifiable form for research, statistical analysis and business intelligence purposes and (c) debugging customer support related issues. Collection, use and disclosure of information which has been designated as Personal Information or Sensitive Personal Data or Information’ under the SPI Rules requires your express consent. By affirming your assent to this Privacy Policy, you provide your consent to such use, collection and disclosure as required under applicable law.

Two types of information may be collected through the Platform and Our Services: Personal Information and Non-Personal Information.  This Privacy Policy does not extend to the collection, use or disclosure of the following information which is currently not limited by applicable privacy laws: (a) information that is publicly available, such as names, addresses, details of the properties, telephone numbers and electronic address when listed in a directory or made available through directory assistance; or (b) Non-Personal Information (as defined further below).

Personal Information is personally identifiable information, which includes the following (“Personal Information”): 
User name; 
User contact details;
Aadhar Card details; 
Address of the property;
Property document details (which are not publicly available and contains personally identifiable data)

It is always Your choice whether or not to provide Personal Information but if you choose not to provide certain requested Personal Information, in some instances you may not be able to register to use the Platform or be able to access and use the Platform at all. In other instances, Your choice not to provide certain other Personal Information may mean that you will not be able to use certain features of the Platform. Apart from this, We may collect Personal Information in respect of the Platform for the following:
	
Storage: We may require this permission so that Your documents can be securely downloaded and saved on Your computer system, and so You can have a seamless and hassle-free experience while using the Platform for availing the Services.

Location Co-ordinates: For the report generated using Valle (“Valle Report”), the application may also use GPS or google maps API to capture real-time location data. 

Feedback Data and Other Data: We may access and save data in the following instances:
When You contact Us or We contact You, We may record information provided by You to service You, or record calls for quality and training purposes;
When You provide any feedback or comments to Us on the Platform or any other platform.

When collecting Personal Information on behalf of another person/entity, it is essential for the User to obtain explicit consent from that individual before sharing their Personal Information with us. This ensures compliance with our privacy policy and respects the privacy rights of all parties involved. In the event you fail to obtain valid consent from the person/entity prior to sharing Personal Information with us, we shall not be liable for the same.

Non-Personal Information is information of an anonymous nature or incomplete nature (that hinders identifiability of an individual, such as only the first name), such as the Internet Protocol Address (IP Address), the domain used to access the Platform, and the type and version of browser or operating system being used by visitors to the Platform (“Non-Personal Information”). Aggregate information, such as demographic statistics of Our Users (e.g. geographical allocation of Our Users), number of visitors, what pages Users access or visit, and average time spent on the Platform is not considered Personal Information.  

Although the use of certain Non-Personal Information collected, used or disclosed through the internet as described herein is not restricted (and to the extent that such is the case, the obligations under this Privacy Policy do not apply to such information), We provide information in this Privacy Policy about the collection of such information for the sake of transparency with respect to the operation of the Platform. Such Non-Personal Information is collected or derived by us in the course of operating this Platform. For example, Our web servers may automatically collect Non-Personal Information that is provided through your browser or stored in a cookie when you choose to visit the Platform. Apart from this, We may collect Non-Personal Information in respect of the Platform for the following:

details of the property (district, state and pin code); 
the geographical co-ordinates of the property;
image of the property; and 
the nature (type and sub-type) of the property.

WHAT DO WE DO WITH YOUR INFORMATION?

Personal Information provided by You will be used to:
open and update Your account on the Platform;
establish and verify Your identity;
provide Services, process Service requests, and offer customer support;
share it with the Service Provider to ensure smooth communication between the parties and for generating the Valle Report;
provide notifications for certain activities relating to Your use of Our Services despite Your indicated e-mail preferences, for example, We may send you notices of any updates to Our Terms of Use or Privacy Policy, forgotten password requests, or security related issues;
undertake research and development;
analyse Your usage of the Platform and to improve the Services on the Platform;
undertake market research, project planning and problem troubleshooting, and to protect against error and fraud.

If You opt-in to receive marketing communications from Us, We will keep you up to date on Our products and services. You may withdraw Your consent to receiving marketing communications from Us at any time by following the opt-out instructions in each communication, or by contacting Our marketing department at [support@navanc.com];

Your Personal Information is subject to rules for disclosure as maybe prescribed under the applicable law, modified from time to time, by governmental authorities, courts and regulatory laws or as otherwise required or not prohibited under the IT Laws.

All Navanc employees and data processors, who have access to, and are associated with the processing of sensitive personal data or information, are obliged to respect the confidentiality of your Personal Information or Sensitive Personal Data and Information. Navanc has put in place procedures and technologies as per good industry practices and in accordance with the applicable laws, to maintain security of all personal data from the point of collection to the point of destruction. Any third-party to which Navanc transfers Personal Data shall have to agree to comply with those procedures and policies, or put in place adequate measures on their own.

To the extent necessary to provide you with Our Services, we may provide your Personal Information to third party contractors who work on behalf of or with us to provide you with such Services, or to maintain the Website or independent third party service providers to process payment instructions. These third-party service providers have access to information needed to process payments, but may not use it for other purposes. In such circumstances, you consent to us disclosing your Personal Information to contractors, solely for the intended purposes only.

REPRESENTATION AND WARRANTIES

You hereby represent to Navanc that:
the information that You provide to Navanc for the purpose of availing its Services from time to time is and shall be authentic, correct, current and updated and You have all the rights, permissions and consents as may be required to provide such information to Navanc;
You are providing the information to Navanc’s consequent storage, collection, usage, transfer, access or processing of the same shall not be in violation of any third party agreement, laws, charter documents, judgments, orders and decrees;
You have the right and have obtained explicit consent (if applicable) to share the Personal Information to Navanc for the purpose of obtaining Our Services. 
You are a resident Indian citizen (as defined under the applicable law) above 18 (Eighteen) years of age and You are sound and competent to contract under the laws of India.

HOW DO I WITHDRAW MY CONSENT? 

If You change Your mind after opting-in, You may withdraw Your consent to contact You, for the continued collection, use or disclosure of Your information, at any time, or request for deletion of your account maintained at Platform by raising a request through Platform or by mailing Us at [insert email address].

However, We shall not retain Your data and information if it is no longer required by Us in relation to providing the Services to you and there is no legal requirement to retain the same. We may also choose to delete all Your Personal Information within 21 (Twenty One) business days of Your account deletion, whichever earlier.

Do note that multiple legal bases may exist in parallel, and We may still have to retain certain data and information at any time.

RIGHT TO RECTIFICATION

In the event that any Personal Information provided by You is inaccurate, incomplete or outdated then You shall have the right to provide Us with accurate, complete and up to date data and have Us rectify such data at Our end immediately. We urge You to ensure that You always provide Us with accurate and correct information/data to ensure Your use of Our Services is uninterrupted.

THIRD-PARTY SERVICES

Only the persons authorized by Navanc will have access to Personal Information. By accepting the terms and conditions You grant complete and unconditional consent (to the extent authorized under the IT Laws) to Navanc for using such information as provided for in this Policy.

CHANGES TO THIS PRIVACY POLICY

We reserve the right to modify this Policy at any time, so please review this Policy frequently. Changes and clarifications will take effect immediately upon their posting on the Platform. Any continued use of the Services shall signify Your acceptance of such new or updated policy, as the case may be. If We are acquired by or merged with another company, Your information may be transferred to the new owners so that We may continue to provide Services to You. If You do not agree with any part of this Policy, please stop using Our Services immediately.

ACCESS TO PERSONAL INFORMATION

You have the right to access the Personal Information We hold about You in order to verify the Personal Information We have collected in respect to You and to have a general account of Our uses of that information. Upon receipt of Your written request, We will provide You with a copy of Your Personal Information although in certain limited circumstances, We may not be able to make all relevant information available to You such as where that information also pertains to another user. In such circumstances We will provide reasons for the denial to You upon request.  We will endeavour to deal with all requests for access and modifications in a timely manner.

We will make every reasonable effort to keep Your Personal Information accurate and up-to-date, and We will provide You with mechanisms to update, correct, delete or add to Your Personal Information as appropriate. As appropriate, this amended Personal Information will be transmitted to those parties to which We are permitted to disclose Your information.

SECURITY

Your Personal Information is treated strictly confidential. To protect Your Personal Information, We take commercially reasonable efforts and follow industry's best practices to make sure Your Personal Information is not inappropriately lost, misused, accessed, disclosed, altered or destroyed. We have in place appropriate safeguards and security measures to protect the Personal Information You provide on this Platform against accidental or any other unlawful forms of processing.

In instances where You make payments to us, the Platform works to help protect the security of Your payment information during transmission by using industry standard Secure Sockets Layer (SSL) encryption technology. 
You are also responsible for helping to protect the security of Your Personal Information. For instance, never give out Your email account information or Your password for the Services to unauthorised third parties.

In accordance with the IT Laws, the name and contact details of the Grievance Officer are provided below:

The Grievance Officer:

Name: Byomkesh Jha & Nagachethan S M
Email: grievanceofficer@navanc.com
Name of the Company: Navanc Data Sciences Private Limited 
Address: Awfis, Renaissance Centra,27 & 27/1, Mission Rd, Sampangiram Nagar, Bengaluru, Karnataka-560027,India
Business Hours: 10 AM  - 6PM, Monday To Friday 

Any grievance with respect to this Policy shall be addressed to the above-mentioned details.

COOKIES

When You visit the Platform, one or more cookies will be sent to the computer/smartphone or other devices being used by You. The cookies are either used to improve the quality of the Platform or for storing Your preferences as a User, improving search results and tracking user trends. You have the option of disabling cookies via Your browser preferences. However, if You disable cookies on Your browser, some parts of the Platform may not function effectively.

YOUR RESPONSIBILITY FOR THE INFORMATION YOU PROVIDE

You may complete a sign-up process as part of Your use of Platform which may include the creation of a username, password and/or other identification information. Any username, password and/or other identification information must be kept confidential by You and must not be disclosed to, or shared with, anyone.

COPYRIGHT, TRADEMARK, AND OTHER INTELLECTUAL PROPERTY PROTECTION

Any unauthorized use of intellectual property rights of Navanc, including but not limited to trade marks, trade names, business names, logos, will be in violation of copyright laws, trademarks laws, intellectual property laws, and other applicable laws and regulations and We will proceed to take prohibitory and injunctive actions as We deem fit to restrict the same. 

TERMINATION

You or We may suspend or terminate Your account or Your use of the Platform at any time, for reasons provided herein or the Terms of Use. We reserve the right to change, suspend, or discontinue all or any aspects of this Platform at any time without notice.

Please note that if you revoke any mandatory permissions or revoke the consent to process and store Personal Information or any other information needed to facilitate Your access to the Services, then We may have to cease the provision of Services to You. You cannot withdraw Your consent once You have availed the Services till You have repaid the entire outstanding amount and all related charges, such as emergency security in its entirety to Us.

INDEMNIFICATION

You agree to defend, indemnify and hold Navanc (its affiliates, subsidiaries, directors, officers, agents, or business partners, with whatsoever name called) harmless, on demand, from and against any and all claims, damages, costs, and expenses, including attorneys’ fees, arising from or related to Your use of the Platform.

DISPUTE RESOLUTION

Any dispute arising out of this Policy shall be resolved in an amicable manner. You can reach out to the grievance officer appointed by us for registration and resolution of any issue. In the event of any dispute, difference or claim arising out of this Policy the same shall be settled in accordance with the laws of India through regular judicial process and the courts of Bengaluru, Karnataka, India shall have exclusive jurisdiction.

CONSENT TO THIS POLICY

By accessing Our Platform, You agree to this Policy or any updated version of this Policy. This document supersedes any prior communication on this topic and reflects the entire and exclusive Policy for the Platform.

DISCLAIMERS

Unless otherwise explicitly stated in the Terms of Use (as may be published on  https://www.navanc.com/termsofuse), the information collected by Navanc through Valle shall be subject to the following conditions:

The Platform is not intended for use by persons located in, or resident in, countries that restrict the use of the Services, or prohibit the manner in which Services are offered. Navanc (or another member thereof) retains the absolute right to determine eligibility for subscription to any of the Services.

Navanc will not be liable to You or any third party for any claim to the extent that the same is characterized as a claim for, or arising from:
Loss of profits or anticipated savings
Loss of goodwill or injury to reputation
Loss of business opportunity
Loss of data
Loss as suffered by third parties
Indirect, consequential or special loss or damage regardless of the form of action, whether in contract, strict liability or tort (including negligence) and regardless of whether Navanc knew or had reason to know of the possibility of the loss, injury or damage in question.

Navanc is not liable for any failure to perform any obligation caused by matters beyond Our reasonable control (including without limitation any failure of transmission, inability to access the Site or Forum or any failure, error or delay in the sending or receiving of any communication).

The information contained in these pages is not intended to provide professional advice. Persons accessing these pages should obtain appropriate professional advice when necessary.

Our trademarks and copyright

The content and information contained within Our website or delivered to You in connection with Your use of Our website is the property of Navanc and any other third party (where applicable). The trademark, trade names and logos (the “Trademarks”) that are used and displayed on Our website include registered and unregistered Trade Marks of Ourselves and other third parties. 

Nothing on Our website should be construed as granting any license or right to use any Trademarks displayed on Our website. We retain all proprietary rights on Our website. Users are prohibited from using the same without written permission of Navanc of such or such other parties. 

The materials on this Platform, including the Valle Report, are protected by copyright and no part of such materials may be modified, reproduced, stored in a retrieval system, transmitted (in any form or by any means), copied, distributed, used for creating derivative works or used in any other way for commercial or public purposes without Navanc's prior written consent.

No warranties

Whilst every care has been taken in preparing the information materials contained in this site, such information and materials are provided “as is” without warranty of any kind, either express or implied. In particular, no warranty regarding non-infringement, security, accuracy, fitness for a purpose or freedom from computer viruses is given in connection with such information and materials.

E-mail

E-mail messages sent to Navanc over the internet cannot be guaranteed to be completely secure. Navanc is not responsible for any damages incurred by users if they send a message to Navanc, or if Navanc sends a message to them at their request, over the Internet. Navanc is not responsible in any way for direct, indirect, special or consequential damages arising out of the use of this Website.

Transmitting over the internet

Due to the nature of the Internet transactions may be subject to interruption, transmission blackout, delayed transmission and incorrect data transmission. Navanc is not liable for malfunctions in communications facilities not under its control that may affect the accuracy or timeliness of messages and transactions You send.

Downloading

We do not represent or warrant that the Platform will be available and meet Your requirements, that access will not be interrupted, that there will be no delays, failures, errors or omissions or loss of transmitted information, that no viruses or other contaminating or destructive properties will be transmitted or that no damage will occur to Your computer system. You have sole responsibility for adequate protection and back up of data and/or equipment and for undertaking reasonable and appropriate precautions to scan for computer viruses or other destructive properties. We make no representations or warranties regarding the accuracy, functionality or performance of any third party software that may be used in connection with the Platform.

Hyperlink policy

Wherever provided, by providing hyperlinks to an external website or webpage, Navanc does not and shall not be deemed to endorse, recommend, approve, guarantee or introduce any third parties or the services/products they provide on their websites, or to have any form of cooperation with such third parties and websites unless otherwise stated by Navanc. We are not in any way responsible for the content of any externally linked website or webpage. You use or follow these links at Your own risk and Navanc is not responsible for any damages or losses incurred or suffered by You arising out of or in connection with Your use of the link. Navanc is not a party to any contractual arrangements entered into between You and the provider of the external website unless otherwise expressly specified or agreed to by Navanc.

Any links to websites that contain downloadable software are provided for Your convenience only. We are not responsible for any difficulties You may encounter in downloading the Platform or for any consequences from Your doing so. Please remember that the use of any Platform downloaded from the internet may be governed by a license agreement and Your failure to observe the terms of such license agreement may result in an infringement of intellectual property rights of the relevant software provider which we are not in any way responsible.

As and where applicable, please be mindful that when You click on a link and leave Our website You will be subject to the terms of use and privacy policies of the other website that You are going to visit.



</p>
                   </>
                }
            </div>
            <Footer />
        </>
    )
}

export default PrivacyPolicy