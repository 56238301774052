import NavBar from '../components/navbar.js'
import Footer from '../components/footer'
import './styles/about.css'
import PeopleContainer from 'src/components/about/people-container/people-container.jsx'

const About = () => {
    return(
        <div className="about-page">
            <NavBar />
            <div className="team-people-container">
                <PeopleContainer></PeopleContainer>
            </div>
            <Footer />
        </div>
    )
}

export default About