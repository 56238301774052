import React, { useEffect, useState } from 'react';
import { Paper, Button } from '@mui/material'
import Carousel from 'src/components/carousel/carousel';
import 'src/components/styles/testimonials.css'
import cp_mohan_image from "src/assets/people/cp-mohan.jpg";
import samit_image from "src/assets/people/samit.jpg";


const TestimonialElement = (props) => {
    return (
        <div className='testimonial-element-container'>
            <div className='testimonial-image-column'>
                <img className='testimonial-image' src={props.image_src} />
                <div className='testimonial-name'>
                    {props.name}
                </div>
            </div>
            <div className='testimonial-text-column'>
                <div className='testimonial-text'>
                    {props.text}
                </div>
            </div>
        </div>
    )
}

const testimonials = [
    <TestimonialElement
        name="Samit Shetty"
        image_src={samit_image}
        text="The growth of Credit bureaus made the process of credit diligence and personal loan delivery seamless. A similar seamless bureau is needed in the space of title diligence and marketability diligence. Navanc's approach to this problem and the expertise of the team in the mortgages should seed the creation of a seamless experience in Title and marketability diligence of Properties. This has the potential to make underwriting in the Mortgage lending space more objective and nuanced."
    />,
    <TestimonialElement
        name="C.P. Mohan"
        image_src={cp_mohan_image}
        text="Navanc seeks to resolve an important puzzle in the collateral based lending space. It is a complex and arduous path as the data sourcing and the regulatory frameworks are very complex. The task that Navanc sets out to do is to ease the complexity of the process and create a score based Decision Support System. It is a very exciting area waiting for the idea of Navanc! "
    />
]

const Testimonial = (props) => {
    return (
        <div className="testimonials-container">
            <div className="testimonials-header">Testimonials</div>
            <Carousel items={testimonials} />
        </div>
    )
}


export default Testimonial