
import '../styles/navanc-intro.css'
import blurred_report from '../../assets/blurred-report.png'
import ScrollReveal from 'src/components/scroll-reveal/scroll-reveal';
import valuation_report_image from 'src/assets/valuation-report.jpg';
import legal_report_image from 'src/assets/legal-report.jpg';
import navanc_logo from 'src/assets/navanc-logo-white.png';
import valle_logo from 'src/assets/valle-logo-white.png';
import satellite from 'src/assets/satellite.png';
import supreme_court from 'src/assets/supreme-court.png';
import SettingsIcon from '@mui/icons-material/Settings';
import Heading from 'src/components/landing_pages/heading';
import Button from 'src/components/button/button'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {animatrix} from './animations_vector.js'
import {useState, useEffect} from 'react'

const NavancIntro = () => {


    const onClickKnowMoreNavanc = () => {
        window.location.href = "#bank-signup";
    }
    
    return(
        <div className="navanc-intro">
        
            <ScrollReveal animationMatix={animatrix} id="first-and-only" order={0}>
                <div className='navanc-introduction-line'>
                    India's first and only <span className='navanc-introduction-line-emph'>comprehensive score</span> for property. 
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="reduce-risk" order={1}>
                <div className='navanc-introduction-line'>
                    <span className='navanc-introduction-line-emph'>Reduce Risk.</span> Leverage More.
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="reduce-time" order={2} top='55vh' left='5vw' start={1800} duration={4000 - 1800}>
                <div className='navanc-introduction-line'>
                <span className='navanc-introduction-line-emph'>Reduce</span> Loan Sanction <span className='navanc-introduction-line-emph'>Time.</span>
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="confident-lifetime" order={3} top='65vh' left='5vw' start={2200} duration={4000 - 2200}>
                <div className='navanc-introduction-line'>
                Create<span className='navanc-introduction-line-emph'> Life Time Value </span> to the Asset  Underwritten.
                </div>
            </ScrollReveal>

            <ScrollReveal animationMatix={animatrix} id="lawyers-valuers" order={4} top='25vh' left='55vw' start={2600} duration={13000 - 2600}>
                <div className='navanc-introduction-line'>
                    Curated Network of <span className='navanc-introduction-line-emph'>Lawyers, Valuers</span> and other Service Providers.
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="coverage-urban-semiurban" order={5} top='45vh' left='55vw' start={3000} duration={13000 - 3000}>
                <div className='navanc-introduction-line'>
                    Service coverage across <span className='navanc-introduction-line-emph'>Urban, Semi-Urban and Rural</span> India.
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="integrated-workflows" order={6} top='60vh' left='55vw' start={3400} duration={13000 - 3400}>
                <div className='navanc-introduction-line'>
                    Integrated Workflows to enable <span className='navanc-introduction-line-emph'>hassle free communication</span> between Financial Institutions, Customer and Service provider.
                </div>
            </ScrollReveal>
         

            <ScrollReveal animationMatix={animatrix} id="how" order={7} top='7vh' left='8vw' start={4000} duration={12200 - 4000}>
                <div className='navanc-intro-heading'>
                    How
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="works" order={8} top='7vh' left='32vw' start={4400} duration={12200 - 4400}>
                <div className='navanc-intro-heading'>
                    works.
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="navanc-logo-ani" order={9} top='7vh' left='15vw' start={600} duration={14000-600}>
                <img src={navanc_logo} id="navanc-logo" className='navanc-intro-logo' />
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="valle-logo-ani" order={10} top='6vh' left='65vw' start={600} duration={14000-600}>
                <img src={valle_logo} id="valle-logo" className='navanc-intro-logo' />
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="valuation-n-legal" order={11} top='37vh' left='10vw' start={4800} duration={1100}>
                <div className='navanc-intro-heading'>
                    Do you already have the <span className='navanc-introduction-line-emph'>valuation and legal reports</span> for your property?
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="step1" order={12} top='15vh' left='8vw' start={5600} duration={1100}>
                <div className='navanc-intro-heading'>Step 1:</div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="valuation-report-image" order={13} top='25vh' left='15vw' start={5600} duration={1100}>
                <img className='valuation-report-image' src={valuation_report_image}/>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="legal-report-image" order={14} top='35vh' left='19vw' start={5600} duration={1100}>
                <img className='valuation-report-image' src={legal_report_image}/>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="navanc-intro-upload" order={15} top='75vh' left='5vw' start={5600} duration={1100}>
                <span className="navanc-intro-upload-container">
                    <UploadFileIcon className="navanc-intro-upload" fontSize='inherit'></UploadFileIcon>
                </span>
                <div className='navanc-introduction-line'>Upload the <span className='navanc-introduction-line-emph'>Valuation and Legal</span> reports for the property</div>
            </ScrollReveal>

            <ScrollReveal animationMatix={animatrix} id="step2" order={16} top='15vh' left='8vw' start={6700} duration={1100}>
                <div className='navanc-intro-heading'>Step 2:</div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="supreme-court" order={17} top='25vh' left='5vw' start={6700} duration={1100}>
                <img src={supreme_court} className='navanc-intro-logo' />
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="satellite" order={18} top='25vh' left='30vw' start={6700} duration={1100}>
                <img src={satellite} className='navanc-intro-logo' />
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="auxilary-info" order={19} top='70vh' left='8vw' start={6700} duration={1100}>
                <div className='navanc-introduction-line'>Navanc will gather auxilary information from <span className='navanc-introduction-line-emph'>Government and Geo Databases</span></div> 
            </ScrollReveal>

            <ScrollReveal animationMatix={animatrix} id="step3" order={20} top='15vh' left='8vw' start={7800} duration={9000-7800}>
                <div className='navanc-intro-heading'>Step 3:</div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="navanc-augment" order={21} top='28vh' left='7vw' start={7800} duration={9000 - 7800}>
                <span className='navanc-introduction-line'>Navanc <span className='navanc-introduction-line-emph'>Augments</span> the valuation and legal report data.</span>
                <span className="navanc-intro-gear-container">
                    <SettingsIcon className="navanc-intro-gear" fontSize='inherit'> </SettingsIcon>
                </span>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="navanc-cross-verify" order={22} top='45vh' left='7vw' start={8200} duration={9000 - 8200}>
                <span className='navanc-introduction-line'><span className='navanc-introduction-line-emph'>Cross-Verifies</span> across various sources.</span>
                <span className="navanc-intro-gear-container">
                    <SettingsIcon className="navanc-intro-gear" fontSize='inherit'> </SettingsIcon>
                </span>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="navanc-predict-insights" order={23} top='62vh' left='7vw' start={8600} duration={9000 - 8600}>
                <span className='navanc-introduction-line'><span className='navanc-introduction-line-emph'>Predicts</span> new insights.</span>
                <span className="navanc-intro-gear-container">
                    <SettingsIcon className="navanc-intro-gear" fontSize='inherit'> </SettingsIcon>
                </span>
            </ScrollReveal>

            <ScrollReveal animationMatix={animatrix} id="step4" order={24} top='15vh' left='8vw' start={9000} duration={12400-9000}>
                <div className='navanc-intro-heading'>Step 4:</div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="blurred-report" order={25} top='25vh' left='9vw' start={9000} duration={13000 - 9000}>
                <img className="report-in-intro" src={blurred_report} />
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="navanc-download-icon" order={26} top='75vh' left='4vw' start={9000} duration={800}>
                <span className="navanc-intro-download-container">
                    <FileDownloadIcon className="navanc-intro-download" fontSize='inherit'></FileDownloadIcon>
                </span>
                <span className='navanc-introduction-line'><span className='navanc-introduction-line-emph'>Download</span> the PDF Report.</span>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="navanc-intro-arrow-1" order={27} top='30vh' left='30vw' start={9800} duration={800}>
                <span className="navanc-intro-arrow-container">
                    <ArrowRightAltIcon className="navanc-intro-arrow" fontSize='inherit'> </ArrowRightAltIcon>
                </span>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="navanc-overallscore-ani" order={28} top='80vh' left='7vw' start={9800} duration={800}>
                <div className='navanc-introduction-line'> Use the <span className='navanc-introduction-line-emph'>overall score</span> to understand the overall health.</div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="navanc-intro-arrow-2" order={29} top='40vh' left='30vw' start={10600} duration={800}>
                <span className="navanc-intro-arrow-container">
                    <ArrowRightAltIcon className="navanc-intro-arrow" fontSize='inherit'> </ArrowRightAltIcon>
                </span>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="navanc-insights-ani" order={30} top='80vh' left='7vw' start={10600} duration={800}>
                <div className='navanc-introduction-line'> Use insights to understand where the property <span className='navanc-introduction-line-emph'>performed well or not.</span></div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="navanc-intro-arrow-3" order={31} top='55vh' left='35vw' start={11400} duration={800}>
                <span className="navanc-intro-arrow-container">
                    <ArrowRightAltIcon className="navanc-intro-arrow" fontSize='inherit'> </ArrowRightAltIcon>
                </span>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="navanc-sectionwisescore-ani" order={32} top='80vh' left='7vw' start={11400} duration={800}>
                <div className='navanc-introduction-line'> Use the section-wise score to <span className='navanc-introduction-line-emph'>understand nuances.</span> about the property</div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="navanc-knowmore-ani" order={33} top='80vh' left='13vw' start={12200} duration={800}>
                <div className='know-more-button' onClick={() => onClickKnowMoreNavanc()}>
                    <div className="know-more-button-text">I want to know more</div> 
                    <div className="know-more-button-arrow-container">
                        <ArrowRightAltIcon sx={{fontSize: "50px"}}> </ArrowRightAltIcon>
                    </div>
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="valle-how" top='7vh' left='57vw' start={13000} duration={17300 - 13000}>
                <div className='navanc-intro-heading'>
                    How
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="valle-works" top='7vh' left='83vw' start={13400} duration={17300 - 13400}>
                <div className='navanc-intro-heading'>
                    works.
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="valle-question" top='40vh' left='60vw' start={13800} duration={1100}>
                <div className='navanc-intro-heading'>
                    <span className='navanc-introduction-line-emph'>Don't</span> already have the valuation and legal reports for your property?
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="valle-prop-docs" top='25vh' left='55vw' start={14900} duration={17300-14900}>
                <div className='navanc-introduction-line'>
                    Upload the other property documents such as <span className='navanc-introduction-line-emph'> the deed and encumberance</span> among others.
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="valle-network" top='45vh' left='55vw' start={15300} duration={17300-15300}>
                <div className='navanc-introduction-line'>
                    Your property will get assigned to our <span className='navanc-introduction-line-emph'>network of valuers and lawyers.</span>
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="valle-download" top='65vh' left='55vw' start={15700} duration={17300-15700}>
                <div className='navanc-introduction-line'>
                    <span className='navanc-introduction-line-emph'>Download</span> your valuation and legal reports.
                </div>
            </ScrollReveal>
            <ScrollReveal animationMatix={animatrix} id="valle-know-more" top='80vh' left='64vw' start={16500} duration={17300 - 16500}>
                <div className='know-more-button' onClick={() => onClickKnowMoreNavanc()}>
                    <div className="know-more-button-text">I want to know more</div> 
                    <div className="know-more-button-arrow-container">
                        <ArrowRightAltIcon sx={{fontSize: "50px"}}> </ArrowRightAltIcon>
                    </div>
                </div>
            </ScrollReveal>
        </div>
    )
}

export default NavancIntro