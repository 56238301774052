import NavBar from 'src/components/navbar.js'
import Footer from 'src/components/footer'
import './navanc-product-page.css'
import BankSignup from 'src/components/landing_pages/bank-signup'
import nanvac_graphics from 'src/assets/Navanc.jpg'

const NavancProductPage = () => {
    return (
        <>
            <NavBar />
            <div className="container">
                <div className="graphics-container">
                    <img src={nanvac_graphics} alt="navanc" />
                </div>
                <div className="navanc-first-section">
                    <div>
                        Navanc is a comprehensive property score. Usually, each property is identified by a unique number - a plot number, flat number, door number, and so on. Navanc, in Sanskrit, means a new number; here, it signifies a new number which defines the creditworthiness of the property. Our property creditworthiness score - NAVANC is built on a proprietary algorithm based on around 60 parameters drawn from multiple data sources. It increases the confidence levels of credit and risk managers.
                    </div>
                </div>
            </div>
            <BankSignup />
            <Footer />
        </>
    )
}

export default NavancProductPage