import "src/components/styles/funded-by.css";
import { Link } from "react-router-dom";
import ciie_logo from "src/assets/ciie.png";
import sheltertech_logo from "src/assets/sheltertech_newlogo.png";
import brigade_reap from "src/assets/brigade-reap.png";
import villgro_logo from "src/assets/Villgro_Logo.png";

const FundedBy = () => {
  const onClickVillgro = () => {
    console.log("villgro");
    window.open("https://villgro.org/");
  };
  const onClickCiie = () => {
    console.log("ciee");
    window.open("https://ciie.co/");
  };

  const onClickShelterTech = () => {
    window.open("http://sheltertech.in/about.html");
  };

  const onClickBrigadeReap = () => {
    window.open("https://brigadereap.com/");
  };

  return (
    <div className="funded-by-container">
      <div className="funded-by-heading">Generously powered by</div>
      <div className="funded-by-details">
        <div onClick={() => onClickVillgro()} className="funded-by-detail">
          <img id="ciie" src={villgro_logo} />
        </div>
        <div onClick={() => onClickCiie()} className="funded-by-detail">
          <img id="ciie" src={ciie_logo} />
        </div>
        <div onClick={() => onClickShelterTech()} className="funded-by-detail">
          <img id="sheltertech" src={sheltertech_logo} />
        </div>
        <div onClick={() => onClickBrigadeReap()} className="funded-by-detail">
          <img id="brigade-reap" src={brigade_reap} />
        </div>
      </div>
    </div>
  );
};

export default FundedBy;
