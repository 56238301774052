import DynamicCounterElement from "../dynamicCounterElement/dynamicCounterElement.js"
import '../styles/dynamicCounter.css'

const DynamicCounter = () => {
    return(
        <div className="dynamic-counter">
            <div>
                <DynamicCounterElement 
                    number={6} 
                    title="States" 
                    subtitle="..and expanding"
                    duration={1}
                />
                <DynamicCounterElement
                    number={2}
                    title="HFC"
                    subtitle="tied up"
                    additionalText="+"
                    duration={1}
                />
                <DynamicCounterElement
                    number={7}
                    title="NBFC"
                    subtitle="tied up"
                    additionalText="+"
                    duration={1}
                />
                <DynamicCounterElement 
                    number={150} 
                    title="Valle" 
                    subtitle="Service Providers"
                    duration={2}
                    additionalText="+"
                />
                <DynamicCounterElement 
                    number={5000} 
                    title="Reports" 
                    subtitle="Generated and Counting on Navanc"
                    duration={2}
                    additionalText="+"
                />
                <DynamicCounterElement 
                    number={10000} 
                    title="Reports" 
                    subtitle="Generated and Counting on Valle"
                    duration={2}
                    additionalText="+"
                />
            </div>
        </div>
    )
}

export default DynamicCounter