import PersonCard from "src/components/about/person-card/V1.0/person-card"
import NavBar from "src/components/navbar"
import Footer from "src/components/footer"
import "./advisors.css"

import cpmohan from "src/assets/people/cp-mohan.jpg"
import chattanathan from "src/assets/people/chattanathan.jpg"
import samit from "src/assets/people/samit.jpg"

const Advisors = () => {
    return(
        <>
            <NavBar />
            <div className="advisor-list">
                <PersonCard
                    person_name="CP Mohan"
                    person_image_src={cpmohan}
                    designation="Chief General Manager Retd at NABARD"
                    person_description="
                    CP Mohan is a development finance professional with 40 years of experience in grassroots to policy level positions across public, private, cooperative, and multilateral institutions. 
                    He has served on the Boards of several development institutions and RRBs.
                    "
                    linkedin_link="https://www.linkedin.com/in/mohan-cp-803790147"
                />
                <PersonCard
                    person_name="Chattanathan D"
                    person_image_src={chattanathan}
                    designation="Co-Founder, Arya.ag"
                    person_description="
                        Chattanathan is the Co-Promoter of Arya.ag. 
                        He has more than 3 decades of work experience. 
                        He held the position of Group Product Head in ICICI Bank prior to joining Arya.ag. 
                        He is an angel investor in more than a dozen startups and is also an author of 3 books. 
                        MY RIDES with SAHIB is an award-winning fiction.
                    "
                    linkedin_link="https://www.linkedin.com/in/chattanathan-devarajan-06692816"
                />
                <PersonCard
                    person_name="Samit Shetty"
                    person_image_src={samit}
                    designation="Board of Directors, Chaitanya India"
                    person_description="
                    Samit Shetty is presently involved with NIVASA, an NGO focusing on the Housing Sector. 
                    He is also on the Board of Directors of Chaitanya India, which is a group concern of Navi. 
                    He was the co-founder and CEO of Chaitanya before it became part of Navi and later moved on to become the CEO of Navi Finserv. Currently, he is interested in mentoring start-up entrepreneurs and understanding the nuances of various sectors like Healthcare, Housing, and Finance. 
                    He is an avid listener and reader. He is a graduate of the Indian Institute of Management, Ahmedabad.
                    "
                    linkedin_link="https://www.linkedin.com/in/samit-shankar-shetty-663875"
                />
            </div>
            <Footer />
        </>        
    )
}

export default Advisors
