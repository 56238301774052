import React, { useEffect, useState } from "react";
import "src/components/carousel/carousel.css";

const useHover = () => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = React.useCallback(() => setIsHovering(true), []);
  const handleMouseOut = React.useCallback(() => setIsHovering(false), []);

  const nodeRef = React.useRef();

  const callbackRef = React.useCallback(
    (node) => {
      if (nodeRef.current) {
        nodeRef.current.removeEventListener("mouseover", handleMouseOver);
        nodeRef.current.removeEventListener("mouseout", handleMouseOut);
      }

      nodeRef.current = node;

      if (nodeRef.current) {
        nodeRef.current.addEventListener("mouseover", handleMouseOver);
        nodeRef.current.addEventListener("mouseout", handleMouseOut);
      }
    },
    [handleMouseOver, handleMouseOut]
  );

  return [callbackRef, isHovering];
};

const Carousel = (props) => {
  var [index, setIndex] = useState(0);
  const [hoverRef, isHovering] = useHover();
  console.log(index);

  const timer = () => {
    var new_index = index;
    new_index++;
    if (new_index === props.items.length) {
      new_index = 0;
    }
    setIndex(new_index);
  };

  useEffect(() => {
    const temp_timer = setTimeout(timer, 4000);

    if (isHovering) {
      clearTimeout(temp_timer);
    }
    return () => clearTimeout(temp_timer);
  }, [index, isHovering]);

  useEffect(() => {
    var temp_timer;

    if (!isHovering) {
      temp_timer = setTimeout(timer, 1000);
    }

    return () => clearTimeout(temp_timer);
  }, [isHovering]);

  return (
    <div className="carousel-container" ref={hoverRef}>
      {props.items[index]}
    </div>
  );
};

export default Carousel;
