import navanc_logo from '../assets/navanc-logo-white.png'
import "./styles/navbar.css"
import { Link, useNavigate } from "react-router-dom"
import NaviconDropdown from "src/components/navicon-dropdown/navicon-dropdown.jsx"
import zIndex from '@mui/material/styles/zIndex'

const product_links = [
    ["Navanc", "/navanc"],
    ["Valle", "/valle"],
    ["n-Lite","/n-lite"]
]

const NavBar = (props) => {
    const navigate = useNavigate();

    const onClickLogo = () => {
        navigate('/');
    }

    const onClickAbout = () => {
        navigate('/about');
    }
    
    const onClickAdvisors = () => {
        navigate('/advisors')
    }

    const onClickBlogs = () => {
        navigate('/blogPages')
    }


    var style = {};
    if (props.transparent) {
        style['background-color'] = "transparent";
    }
    return(
        <div style={style} className="navbar">
            <div className="logo-container" onClick={() => onClickLogo()}>
                <img className="navbar-logo" src={navanc_logo} alt="navanc logo"/>
            </div>
            <div className="nav-links">
                <div className="nav-link" onClick={() => onClickAbout()}>
                    <div>About</div>
                </div>
                <div className="nav-link">
                   <NaviconDropdown
                        drop_down_items={product_links}
                        label="Products"
                    >    
                    </NaviconDropdown>
                </div>
                <div className="nav-link" onClick={() => onClickBlogs()}>
                    <div>Blogs</div>
                </div>
                <div className='nav-link'  onClick={() => onClickAdvisors()}>
                    <div>Advisors</div>
                </div>
            </div>
        </div>
    )
}

export default NavBar