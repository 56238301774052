import { useState, useEffect } from 'react'
import CountUp from 'react-countup'
import './dynamicCounterElement.css'

const DynamicCounterElement = (props) => {
    const windowHeight = window.innerHeight
    const [divY, setDivY] = useState(windowHeight+10)

    const handleScroll = () => {
        const div_y = document.getElementsByClassName('dynamic-counter')[0].getBoundingClientRect().y
        setDivY(div_y)
    }
    useEffect(() => {
        handleScroll()
        window.addEventListener('scroll', handleScroll);
    }, [])
    
    return (
        <div className="dynamic-counter-element">
            <div className="dynamic-counter-number">
                {console.log(divY, windowHeight)}
                {(divY < windowHeight)?<CountUp
                    end={props.number}
                    duration = {props.duration}
                />:0}
                {props.additionalText}
            </div>
            <div className="dynamic-counter-title">{props.title}</div>
            <div className="dynamic-counter-subtitle">{props.subtitle}</div>
        </div>
    )
}


export default DynamicCounterElement