import "./App.css";
import NavBar from "./components/navbar.js";
import Footer from "./components/footer";
import { useState, useEffect } from "react";
import HeadTurner from "./components/landing_pages/HeadTurner.js";
import NowVsNavanc from "./components/landing_pages/now-vs-navanc";
import FundedBy from "src/components/landing_pages/funded-by";
import WhyNavac from "./components/landing_pages/whyNavanc";
import Products from "./components/landing_pages/products";
import Testimonials from "src/components/landing_pages/testimonials";

import NavancIntro from "src/components/landing_pages/navanc-intro";
import ValuerLawyerSignup from "src/components/landing_pages/valuer-lawyer-signup";
import BankSignup from "src/components/landing_pages/bank-signup";
import Newsletter from "./components/newsletter/newsletter";
import DynamicCounter from "src/components/landing_pages/dynamicCounter";
import SlideShow from "./components/slideshow/slideshow";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function App() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="App">
      <HeadTurner />
      <NavancIntro />
      <NowVsNavanc />
      <BankSignup />
      <ValuerLawyerSignup />
      <DynamicCounter />
      <SlideShow />
      <FundedBy />
      <Testimonials />
      <Newsletter />
      <Footer />
      {scroll && (
        <div className="scroll-to-top-arrow" onClick={scrollToTop}>
          <ArrowUpwardIcon sx={{ color: "#dfdfdf", fontSize: 80 }} />
        </div>
      )}
    </div>
  );
}

export default App;
