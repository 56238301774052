import NavBar from "../components/navbar.js";
import Footer from "../components/footer";
import "./styles/blogList.css";
import { Link } from "react-router-dom";
import NavancNewsletterQ3 from "../blogposts/Navanc Newsletter Q3.pdf";

const blogFiles = [
  {
    name: "Navanc Newsletter Q1",
    fileType: "json",
    date: new Date(2022, 6, 29),
  },
  {
    name: "Navanc Newsletter Q3",
    fileType: "json",
    date: new Date("2022-12-15T03:24:00"),
    pdfName: NavancNewsletterQ3,
  },
];

const BlogList = () => {
  return (
    <>
      <NavBar />
      <div className="blog-list">
        {blogFiles.map((file) => (
          <div>
            {" "}
            {file.name === "Navanc Newsletter Q1" ? (
              <Link
                to={`/blogPages/${file.fileType}/${file.name}`}
                key={file.name}
                style={{ textDecoration: "None" }}
              >
                <div className="blog-list-element">
                  <div className="blog-list-element-name">{file.name}</div>
                  <div className="blog-list-element-date">
                    {`${file.date.getDate()}/${
                      file.date.getMonth() + 1
                    }/${file.date.getFullYear()}`}
                  </div>
                </div>
              </Link>
            ) : (
              <a href={file.pdfName} target="_blank" rel="noreferrer">
                <div className="blog-list-element">
                  <div className="blog-list-element-name">{file.name}</div>
                  <div className="blog-list-element-date">
                    {`${file.date.getDate()}/${
                      file.date.getMonth() + 1
                    }/${file.date.getFullYear()}`}
                  </div>
                </div>
              </a>
            )}
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default BlogList;
