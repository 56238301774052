import { useLocation } from 'react-router';
import 'src/components/styles/valuer-lawyer-signup.css'

const ValuerLawyerSignup = () => {

    const onClickSignup = () => {
        window.open("https://forms.gle/agVUAke4E8Zi1M399"); 
    }

    const location=useLocation();
    const path=location.pathname
    

    return(
        <div className={path!="/valle"? "valuer-lawyer-signup-container":" valuer-lawyer-signup-container valle-valuer-lawyer-signup-container" }>
            <div className={path!="/valle"?"valuer-lawyer-signup-heading":"valuer-lawyer-signup-heading valle-valuer-lawyer-signup-heading"}>
                Are you a Valuer or a Lawyer?
            </div>
            <div className={path!="/valle"?"valuer-lawyer-signup-details":"valuer-lawyer-signup-details valle-valuer-lawyer-signup-details"}>
                If you are interested in signing up on our platform, fill up the form in the link below.
            </div>
            <div onClick={() => onClickSignup()} className={path!="/valle"?"valuer-lawyer-signup-form-link":"valle-valuer-lawyer-signup-form-link"}>
                Valuers/Lawyers Signup Form
            </div>
        </div>
    )
}

export default ValuerLawyerSignup