//can only handle "p", "h", "hr", "a" and "img"
import "src/components/blogBuilder/blog-builder.css"

const BlogBuilder = (props) => {
    const blogContent = require(`../../blogposts/${props.blogPath}`)
    return(
        <div 
            style={{
                width: "70%",
                padding: "20px",
                backgroundColor: "black",
                color: "white",
                margin: "20px auto",
                fontSize:`1.5em`,
                borderRadius:"12px"
            }}
        >
            {
                blogContent.map(element => {
                    if (element['tag']==="p") {
                        return <p 
                            key={element.text}
                            style={{
                                textAlign: 'justify'
                            }}
                        >{element.text}</p>
                    }
                    if (element['tag']==="h") {
                        return <h 
                            style={{
                                fontSize:`${element['font-size']}`,
                                fontWeight:`${element['font-weight']}`,
                                padding: `10px`
                            }}
                            key={element.text}
                        >
                            {element.text}
                        </h>
                    }
                    if (element['tag']==="img") {
                        const imageSrc = require(`src/blogposts${element.src}`)
                        return <img src={imageSrc} style={{width:`${element.width}`}}/>
                    }
                    if (element['tag']==="hr") {
                        return <hr></hr>
                    }
                    if (element['tag']==="a") {
                        return <a href={element.href} style={{textDecoration: `none`, color: `white`, fontWeight: `800`}}>
                            <u>{element.text}</u>
                        </a>
                    }
                })
            }
        </div>
    )
}

export default BlogBuilder