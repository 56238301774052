import { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

const MdToJsx = (props) => {
    const [blogContent, setBlogContent]  = useState('')

    useEffect(() => {
        import("../../blogposts/"+props.blogPath)
            .then(res =>
                fetch(res.default)
                    .then(response => response.text())
                    .then(response => setBlogContent(response))
                    .catch(err => console.log(err))
            )
    }, [])
    return(
        <>
            <div className='blog-page-content'>
                <ReactMarkdown
                    children={blogContent}
                />
            </div>
        </>
    )
}

export default MdToJsx