import "./personCardV2.css";
import LinkedinLogo from "src/assets/linkedin_logo.png";
const PersonCard = ({
  person_image_src,
  person_name,
  designation,
  person_description,
  linkedin_link,
}) => {
  const onClickLinkedInFn = (linkedin_link) => {
    window.open(linkedin_link);
  };
  return (
    <div className="outer-person-card-container">
      <div className="upper-person-card-container">
        <div className="profile-image-container">
          <img className="person-imageV2" src={person_image_src} />
        </div>
      </div>
      <div className="lower-person-card-container">
        <div className="person-info-container">
          <div className="person-nameV2">{person_name}</div>
          <div className="person-designationV2">{designation}</div>
          <div className="person-descriptionV2">{person_description}</div>
          <div
            className="about-linkedin-logo"
            onClick={() => onClickLinkedInFn(linkedin_link)}
          >
            <img src={LinkedinLogo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonCard;
