import { useNavigate } from "react-router-dom"
import navanc_logo from '../assets/navanc-logo-white.png'
import './styles/footer.css'

const Footer = () => {

    const navigate = useNavigate();
    
    const onClickAboutLink = () => {
        navigate('/about');
    }

    const onClickValleLink = () => {
        navigate('/valle');
    }

    const onClickNavancLink = () => {
        navigate('/navanc');
    }
    const onClicknLiteLink = () =>{
        navigate('/n-lite')
    }

    return(
            <div className="footer">
            <img className="footer-logo" src={navanc_logo} alt="navanc logo"/>
            <div className="footer-cols">
                <div className="footer-col">
                    <h className="footer-header">Registered Address</h>
                    <p className="footer-para">    
                        198 Athmeeya Anubhavanagar,<br />
                        1A Cross Nagarabhavi Road,
                        Bengaluru-560072 <br />
                        India
                    </p>
                </div>
                <div className="footer-col footer-col-margin">
                    <h className="footer-header">Office Address</h>
                    <p className="footer-para">   
                        Awfis, Renaissance Centra,<br />
                        27 & 27/1, Mission Rd,<br />
                        Sampangiram Nagar, Bengaluru<br />
                        Karnataka-560027<br />
                        India
                    </p>
                </div>
                <div className="footer-col footer-col-margin"> 
                    <h className="footer-header">Contact</h>
                    <p className="footer-email">
                        hello@navanc.com
                    </p>
                </div>
                <div className="footer-col footer-about footer-col-margin">
                    <h className="footer-header">Company</h>
                    <p className="footer-link" onClick={() => onClickAboutLink()}>About</p>
                </div>
                <div className="footer-col footer-products footer-col-margin">
                    <h className="footer-header">Products</h>
                    <p className="footer-link" onClick={() => onClickNavancLink()}>Navanc</p>
                    <p className="footer-link" onClick={() => onClickValleLink()}>Valle</p>
                    <p className="footer-link" onClick={() => onClicknLiteLink()}>n-Lite</p>
                </div> 
            </div>
        </div>
    )
}

export default Footer
