import "./person-card.css"
import LinkedInLogo from "./linkedin-logo"



const PersonCard = (props) => {

    const onClickLinkedInFn = (props) => {
        window.open(props.linkedin_link); 
    }

    return (
        <div className="person-card">
            <div className="person-name"> {props.person_name} </div>
           <div className="person-image-container"> <img className="person-image" src={props.person_image_src}/> </div>
            <div className="person-designation"> {props.designation} </div>
            <div className="person-description"> {props.person_description} </div>
            <div className="linked-in-logo" onClick={() => onClickLinkedInFn(props)}><LinkedInLogo></LinkedInLogo></div>
        </div>
    )
}

export default PersonCard;