import NavBar from '../components/navbar.js'
import Footer from '../components/footer'
import './styles/blogPage.css'
import MdToJsx from 'src/components/blog/mdToJsx.js'
import BlogBuilder from 'src/components/blogBuilder/blogBuilder.js'

const BlogPage = () => {
    
    const path = document.URL

    const getBlogFileAndFileType = (path) => {
        const reverseString = (string) => {
            let reversedString = string.split("")
            reversedString = reversedString.reverse()
            reversedString = reversedString.join("")
            return reversedString
        }
        const shortPath = path
        console.log(shortPath)
        let reversedPath = reverseString(shortPath)
        let firstSlash = reversedPath.indexOf('/')
        const reversedBlogFile = reversedPath.slice(0, firstSlash)
        const BlogFile = reverseString(reversedBlogFile)
        reversedPath = reversedPath.slice(firstSlash+1)
        console.log(reversedPath)
        firstSlash = reversedPath.indexOf('/')
        const reversedFileType = reversedPath.slice(0, firstSlash)
        const FileType = reverseString(reversedFileType)

        return [decodeURI(BlogFile), FileType]
    }

    const [BlogFile, FileType] = getBlogFileAndFileType(path)


    return(
        <>
            <NavBar />
            <div className='blog-page'>
                {
                    FileType=="md"?
                    <MdToJsx blogPath={`${BlogFile}.md`}/>
                    :
                    FileType=="json"?
                    <BlogBuilder blogPath={`${BlogFile}.json`}/>
                    :
                    "invalid file type"
                }
            </div>
            <Footer />
        </>
    )
}

export default BlogPage