import 'src/components/styles/bank-signup.css'

const BankSignup = () => {

    const onClickSignup = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSeieNjaZsmKCsZgQFICD3ewjJgwUO_6WrqHExh4W68qPMfZNA/viewform"); 
    }

    return(
        <div id="bank-signup" className="bank-signup-container">
            <div className="bank-signup-heading">
                Do you represent a Bank, NBFC or Lending Institution?
            </div>
            <div className="bank-signup-details">
                If you are interested in knowing more and want us to call you back, fill up the form in the link below.
            </div>
            <div onClick={() => onClickSignup()} className="bank-signup-form-link">
                Lending Instituition Signup Form
            </div>
        </div>
    )
}

export default BankSignup
