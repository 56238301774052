export const top = [
    ['25vh', '15vh', '25vh',],//0 India's first and only
    ['45vh', '45vh', '40vh',],//1 Reduce Risk
    ['55vh', '60vh', '50vh',],//2 Loan Sanction
    ['65vh', '75vh', '60vh',],//3 Lifetime
    ['25vh', '20vh', '25vh',],//4 Layers, Valuers
    ['45vh', '40vh', '45vh',],
    ['60vh', '60vh', '60vh'],
    ['7vh', '7vh', '11vh'],//7
    ['7vh', '7vh', '11vh'],
    ['7vh', '7vh', '10vh'],//9 navanc_logo
    ['6vh', '7vh','12vh'],//10 valle_logo
    ['37vh', '17vh', '37vh'],
    ['15vh', '15vh', '25vh'],//12 step 1
    ['25vh', '25vh', '35vh'],
    ['35vh', '35vh', '45vh'],
    ['75vh', '55vh','65vh'],
    ['15vh', '15vh', '25vh'],//16 step 2
    ['25vh', '20vh', '35vh'],
    ['25vh', '20vh', '35vh'],
    ['70vh', '55vh', '65vh'],
    ['15vh', '15vh', '25vh'],//20 step3
    ['28vh', '20vh', '38vh'],
    ['45vh', '45vh', '55vh'],
    ['62vh', '62vh', '78vh'],
    ['15vh', '15vh', '25vh'],//24 step 4
    ['25vh', '25vh', '35vh'],
    ['75vh', '55vh','65vh'],
    ['30vh', '23vh', '28vh'],
    ['80vh', '55vh', '65vh'],//28 overall score
    ['40vh', '27vh', '33vh'],
    ['80vh', '55vh', '65vh'],
    ['55vh', '35vh', '40vh',],
    ['80vh', '55vh', '65vh'],
    ['80vh', '55vh', '65vh']
]
export const left = [
    ['5vw', '10vw', '15vw'],//0
    ['5vw', '10vw', '15vw'],
    ['5vw', '10vw', '15vw'],
    ['5vw', '10vw', '15vw'],
    ['55vw', '10vw', '10vw'],//4
    ['55vw', '10vw', '10vw'],
    ['55vw', '10vw', '10vw'],
    ['7vw', '15vw', '5vw'], //7 How
    ['45vw', '68vw', '78vw'], //8 Works.
    ['13vw', '20vw', '30vw'],//9 navanc-logo
    ['65vw', '25vw', '30vw'],//10 valle-logo
    ['10vw', '20vw', '10vw'],
    ['8vw', '12vw', '8vw'],//12  step1
    ['15vw','40vw', '30vw'],
    ['19vw', '45vw', '34vw'],
    ['5vw', '15vw', '15vw'],
    ['8vw', '12vw', '8vw'],//16 step2
    ['5vw', '19vw', '5vw'],
    ['30vw', '54vw', '47vw'],
    ['8vw', '12vw', '15vw'],
    ['8vw', '12vw', '8vw'],//20 step3
    ['7vw', '11vw', '7vw'],
    ['7vw', '11vw', '7vw'],
    ['7vw', '11vw', '7vw'],
    ['8vw', '12vw', '8vw'],//24 step 4
    ['9vw', '33vw', '20vw'],
    ['4vw', '15vw', '4vw'],
    ['30vw', '54vw', '60vw'],//27
    ['7vw', '17vw', '7vw'],
    ['30vw', '54vw', '60vw'],
    ['7vw', '17vw', '7vw'],
    ['35vw', '55vw', '60vw'],
    ['7vw', '17vw', '7vw'],
    ['13vw', '20vw', '13vw'],
]
export const start = [
    [1000, 1000, 1000],//0
    [1400, 1400, 1400],
    [1800, 1800, 1800],
    [2200, 2200, 2200],
    [2600, 10400, 10400],//4
    [3000, 10800, 10800],
    [3400, 11200, 11200],
    [4000, 2400, 2400],
    [4400, 2400, 2400],
    [600, 600, 600],//9
    [600, 10000, 10000],//10
    [4800, 2400, 2400],
    [5600, 3200, 3200],
    [5600, 3200, 3200],
    [5600, 3200, 3200],
    [5600, 3200, 3200],
    [6700, 4300, 4300],
    [6700, 4300, 4300],
    [6700, 4300, 4300],
    [6700, 4300, 4300],
    [7800, 5400, 5400],
    [7800, 5400, 5400],
    [8200, 5800, 5800],
    [8600, 6200, 6200],
    [9000, 6600, 6600],
    [9000, 6600, 6600],
    [9000, 6600, 6600],
    [9800, 7400, 7400],
    [9800, 7400, 7400],
    [10600, 8200, 8200],
    [10600, 8200, 8200],
    [11400, 9000, 9000],
    [11400, 9000, 9000],
    [12200, 9800, 9800],
]
export const duration = [
    [4000-1000, (4000-1000)/2, (4000-1000)/2],//0
    [4000-1400, (4000-1400)/2, (4000-1400)/2],
    [4000-1800, (4000-1800)/2, (4000-1800)/2],
    [4000-2200, (4000-2400)/2, (4000-2400)/2],
    [13000-2600, 3000, 13000-2600],//4
    [13000-3000, 2600, 13000-3000],
    [13000-3400, 2200, 13000-3400],
    [12200-4000, 12200-4400, 12200-4000],
    [12200-4400, 12200-4400, 12200-4400],
    [14000-600, 14000-4400, 14000-600],//9
    [14000-600, 4000, 4000],//10
    [1100, 1100, 1100],
    [1100, 1100, 1100],
    [1100, 1100, 1100],
    [1100, 1100, 1100],
    [1100, 1100, 1100],
    [1100, 1100, 1100],
    [1100, 1100, 1100],
    [1100, 1100, 1100],
    [1100, 1100, 1100],
    [9000-7800, 9000-7800, 9000-7800],
    [9000-7800, 9000-7800, 9000-7800],
    [9000-8200, 9000-8200, 9000-8200],
    [9000-8600, 9000-8600, 9000-8600],
    [12400-9000, 12400-9000, 12400-9000],
    [13000-9000, 13000-9000, 13000-9000],
    [800, 800, 800],
    [800, 800, 800],
    [800, 800, 800],
    [800, 800, 800],
    [800, 800, 800],
    [800, 800, 800],
    [800, 800, 800],
    [800, 800, 800],
]

export const animatrix = {
    "first-and-only":{
      '1000px': { left: '5vw', top: '25vh', start: 1000, duration: 3000 },
      '600px': { left: '10vw', top: '15vh', start: 1000, duration: 1500 },
      '250px': { left: '15vw', top: '30vh', start: 1000, duration: 1300 }
    },
    "reduce-risk":{
      '1000px': { left: '5vw', top: '45vh', start: 1400, duration: 2600 },
      '600px': { left: '10vw', top: '45vh', start: 1400, duration: 1300 },
      '250px': { left: '15vw', top: '50vh', start: 1200, duration: 1200 }
    },
    "reduce-time":{
      '1000px': { left: '5vw', top: '60vh', start: 1800, duration: 2200 },
      '600px': { left: '10vw', top: '60vh', start: 1800, duration: 1100 },
      '250px': { left: '15vw', top: '65vh', start: 1400, duration: 1100 }
    },
    "confident-lifetime":{
      '1000px': { left: '5vw', top: '75vh', start: 2200, duration: 1800 },
      '600px': { left: '10vw', top: '75vh', start: 2200, duration: 800 },
      '250px': { left: '15vw', top: '80vh', start: 1600, duration: 1000 }
    },
    "lawyers-valuers":{
      '1000px': { left: '55vw', top: '25vh', start: 2600, duration: 10400 },
      '600px': { left: '10vw', top: '20vh', start: 10400, duration: 3000 },
      '250px': { left: '15vw', top: '25vh', start: 10400, duration: 3200 }
    },
    "coverage-urban-semiurban":{
      '1000px': { left: '55vw', top: '45vh', start: 3000, duration: 10000 },
      '600px': { left: '10vw', top: '40vh', start: 10800, duration: 2600 },
      '250px': { left: '15vw', top: '45vh', start: 10800, duration: 2800 }
    },
    "integrated-workflows":{
      '1000px': { left: '55vw', top: '65vh', start: 3400, duration: 9600 },
      '600px': { left: '10vw', top: '60vh', start: 11200, duration: 2200 },
      '250px': { left: '15vw', top: '60vh', start: 11200, duration: 2400 }
    },
    "how":{
      '1000px': { left: '7vw', top: '7vh', start: 4000, duration: 8200 },
      '600px': { left: '15vw', top: '7vh', start: 2400, duration: 7800 },
      '250px': { left: '7vw', top: '5vh', start: 2400, duration: 8200 }
    },
    "works":{
      '1000px': { left: '32vw', top: '7vh', start: 4400, duration: 7800 },
      '600px': { left: '65vw', top: '7vh', start: 2400, duration: 7800 },
      '250px': { left: '75vw', top: '5vh', start: 2400, duration: 7800 }
    },
    "navanc-logo-ani":{
      '1000px': { left: '15vw', top: '7vh', start: 600, duration: 17700 },
      '600px': { left: '30vw', top: '7vh', start: 600, duration: 9600 },
      '250px': { left: '30vw', top: '5vh', start: 600, duration: 9800 }
    },
    "valle-logo-ani":{
      '1000px': { left: '65vw', top: '6vh', start: 600, duration: 17700 },
      '600px': { left: '25vw', top: '7vh', start: 10000, duration: 8400 },
      '250px': { left: '30vw', top: '6vh', start: 10000, duration: 8400 }
    },
    "valuation-n-legal":{
      '1000px': { left: '10vw', top: '37vh', start: 4800, duration: 1100 },
      '600px': { left: '20vw', top: '17vh', start: 2400, duration: 1100 },
      '250px': { left: '15vw', top: '37vh', start: 2400, duration: 1100 }
    },
    "step1":{
      '1000px': { left: '8vw', top: '15vh', start: 5600, duration: 1100 },
      '600px': { left: '12vw', top: '15vh', start: 3200, duration: 1100 },
      '250px': { left: '8vw', top: '25vh', start: 3200, duration: 1100 }
    },
    "valuation-report-image":{
      '1000px': { left: '15vw', top: '25vh', start: 5600, duration: 1100 },
      '600px': { left: '40vw', top: '25vh', start: 3200, duration: 1100 },
      '250px': { left: '30vw', top: '35vh', start: 3200, duration: 1100 }
    },
    "legal-report-image":{
      '1000px': { left: '19vw', top: '35vh', start: 5600, duration: 1100 },
      '600px': { left: '45vw', top: '35vh', start: 3200, duration: 1100 },
      '250px': { left: '34vw', top: '45vh', start: 3200, duration: 1100 }
    },
    "navanc-intro-upload":{
      '1000px': { left: '5vw', top: '75vh', start: 5600, duration: 1100 },
      '600px': { left: '15vw', top: '55vh', start: 3200, duration: 1100 },
      '250px': { left: '15vw', top: '65vh', start: 3200, duration: 1100 }
    },
    "step2":{
      '1000px': { left: '8vw', top: '15vh', start: 6700, duration: 1100 },
      '600px': { left: '12vw', top: '15vh', start: 4300, duration: 1100 },
      '250px': { left: '8vw', top: '15vh', start: 4300, duration: 1100 }
    },
    "supreme-court":{
      '1000px': { left: '5vw', top: '25vh', start: 6700, duration: 1100 },
      '600px': { left: '19vw', top: '20vh', start: 4300, duration: 1100 },
      '250px': { left: '-10vw', top: '20vh', start: 4300, duration: 1100 }
    },
    "satellite":{
      '1000px': { left: '30vw', top: '25vh', start: 6700, duration: 1100 },
      '600px': { left: '54vw', top: '20vh', start: 4300, duration: 1100 },
      '250px': { left: '45vw', top: '20vh', start: 4300, duration: 1100 }
    },
    "auxilary-info":{
      '1000px': { left: '8vw', top: '70vh', start: 6700, duration: 1100 },
      '600px': { left: '12vw', top: '55vh', start: 4300, duration: 1100 },
      '250px': { left: '15vw', top: '80vh', start: 4300, duration: 1100 }
    },
    "step3":{
      '1000px': { left: '8vw', top: '15vh', start: 7800, duration: 1200 },
      '600px': { left: '12vw', top: '15vh', start: 5400, duration: 1200 },
      '250px': { left: '8vw', top: '15vh', start: 5400, duration: 1200 }
    },
    "navanc-augment":{
      '1000px': { left: '7vw', top: '28vh', start: 7800, duration: 1200 },
      '600px': { left: '11vw', top: '20vh', start: 5400, duration: 1200 },
      '250px': { left: '12vw', top: '35vh', start: 5400, duration: 1200 }
    },
    "navanc-cross-verify":{
      '1000px': { left: '7vw', top: '45vh', start: 8200, duration: 800 },
      '600px': { left: '11vw', top: '45vh', start: 5800, duration: 800 },
      '250px': { left: '12vw', top: '55vh', start: 5800, duration: 800 }
    },
    "navanc-predict-insights":{
      '1000px': { left: '7vw', top: '62vh', start: 8600, duration: 400 },
      '600px': { left: '11vw', top: '62vh', start: 6200, duration: 400 },
      '250px': { left: '12vw', top: '75vh', start: 6200, duration: 400 }
    },
    "step4":{
      '1000px': { left: '8vw', top: '15vh', start: 9000, duration: 3400 },
      '600px': { left: '12vw', top: '15vh', start: 6600, duration: 3400 },
      '250px': { left: '8vw', top: '15vh', start: 6600, duration: 3400 }
    },
    "blurred-report":{
      '1000px': { left: '9vw', top: '25vh', start: 9000, duration: 8400 },
      '600px': { left: '33vw', top: '25vh', start: 6600, duration: 4000 },
      '250px': { left: '20vw', top: '35vh', start: 6600, duration: 4000 }
    },
    "navanc-download-icon":{
      '1000px': { left: '4vw', top: '80vh', start: 9000, duration: 800 },
      '600px': { left: '15vw', top: '55vh', start: 6600, duration: 800 },
      '250px': { left: '7vw', top: '65vh', start: 6600, duration: 800 }
    },
    "navanc-intro-arrow-1":{
      '1000px': { left: '30vw', top: '30vh', start: 9800, duration: 800 },
      '600px': { left: '54vw', top: '23vh', start: 7400, duration: 800 },
      '250px': { left: '60vw', top: '28vh', start: 7400, duration: 800 }
    },
    "navanc-overallscore-ani":{
      '1000px': { left: '7vw', top: '85vh', start: 9800, duration: 800 },
      '600px': { left: '17vw', top: '55vh', start: 7400, duration: 800 },
      '250px': { left: '7vw', top: '65vh', start: 7400, duration: 800 }
    },
    "navanc-intro-arrow-2":{
      '1000px': { left: '30vw', top: '40vh', start: 10600, duration: 800 },
      '600px': { left: '54vw', top: '27vh', start: 8200, duration: 800 },
      '250px': { left: '60vw', top: '33vh', start: 8200, duration: 800 }
    },
    "navanc-insights-ani":{
      '1000px': { left: '7vw', top: '82vh', start: 10600, duration: 800 },
      '600px': { left: '17vw', top: '55vh', start: 8200, duration: 800 },
      '250px': { left: '7vw', top: '65vh', start: 8200, duration: 800 }
    },
    "navanc-intro-arrow-3":{
      '1000px': { left: '35vw', top: '55vh', start: 11400, duration: 800 },
      '600px': { left: '55vw', top: '35vh', start: 9000, duration: 800 },
      '250px': { left: '60vw', top: '40vh', start: 9000, duration: 800 }
    },
    "navanc-sectionwisescore-ani":{
      '1000px': { left: '7vw', top: '82vh', start: 11400, duration: 800 },
      '600px': { left: '17vw', top: '55vh', start: 9000, duration: 800 },
      '250px': { left: '7vw', top: '65vh', start: 9000, duration: 800 }
    },
    "navanc-knowmore-ani":{
      '1000px': { left: '15vw', top: '82vh', start: 12200, duration: 5500 },
      '600px': { left: '20vw', top: '55vh', start: 9800, duration: 800 },
      '250px': { left: '13vw', top: '65vh', start: 9800, duration: 800 }
    },
    //
    "valle-how":{
      '1000px': { left: '55vw', top: '7vh', start: 13000, duration: 4300 },
      '600px': { left: '5vw', top: '7vh', start: 13600, duration: 5200 },
      '250px': { left: '10vw', top: '7vh', start: 13600, duration: 5200 }
    },
    "valle-works":{
      '1000px': { left: '80vw', top: '7vh', start: 13400, duration: 3900 },
      '600px': { left: '75vw', top: '7vh', start: 13600, duration: 5200 },
      '250px': { left: '75vw', top: '7vh', start: 13600, duration: 5200 }
    },
    "valle-question":{
      '1000px': { left: '55vw', top: '25vh', start: 13800, duration: 1100 },
      '600px': { left: '20vw', top: '45vh', start: 14000, duration: 1100 },
      '250px': { left: '16vw', top: '45vh', start: 14000, duration: 1100 }
    },
    "valle-prop-docs":{
      '1000px': { left: '55vw', top: '25vh', start: 14900, duration: 2400 },
      '600px': { left: '10vw', top: '20vh', start: 14900, duration: 3300 },
      '250px': { left: '15vw', top: '25vh', start: 14900, duration: 3300 }
    },
    "valle-network":{
      '1000px': { left: '55vw', top: '45vh', start: 15300, duration: 2000 },
      '600px': { left: '10vw', top: '45vh', start: 15500, duration: 2200 },
      '250px': { left: '15vw', top: '50vh', start: 15500, duration: 2200 }
    },
    "valle-download":{
      '1000px': { left: '55vw', top: '65vh', start: 15700, duration: 1600 },
      '600px': { left: '10vw', top: '65vh', start: 15900, duration: 1100 },
      '250px': { left: '15vw', top: '70vh', start: 15900, duration: 1100 }
    },
    "valle-know-more":{
      '1000px': { left: '64vw', top: '80vh', start: 16500, duration: 1200 },
      '600px': { left: '10vw', top: '85vh', start: 16300, duration: 1500 },
      '250px': { left: '20vw', top: '85vh', start: 16300, duration: 1500}
    }
}

