import React from 'react'
import './slide_show.css'

import naga from '../../assets/people/naga.jpg'
import bs from '../../assets/bs.png'
import ys from '../../assets/ys.png'
import ys_navanc from '../../assets/ys_navanc.jpg'

const news = [
    <div className='slide-child'>
        <img className='article-image' src={ys_navanc}></img>
        <div>
            <img className='news-house' src={ys}></img>
            <h2 className='news-title'>
                Bengaluru startup Navanc is building credit worthiness score for properties
            </h2>
            <p className='news-jist'>
                “The trickiest part is to see the documentation of legal aspects of the property,” 
                Bengaluru-based startup NAVANC is building creditworthiness to scores for properties and assets to simplify and standardise how mortgage-based credit is taken in the country.
            </p>
            <a className='news-link' href='https://yourstory.com/2022/08/bengaluru-startup-navanc-credit-worthiness-property/amp'>
                Read Article
            </a>
        </div>
    </div>,
    <div className='slide-child'>
        <img className='article-image' src={naga}></img>
        <div>
            <img className='news-house' src={bs}></img>
            <h2 className='news-title'>
                Angel Investors Fund Seed Round in Navanc for assessing property creditworthiness
            </h2>
            <p className='news-jist'>
                Navanc Data Sciences Pvt. Ltd. today announced closing of its seed round of funding of 300K USD which saw participation from leading angel investors including Kunal Shah, Founder of CRED and QED Innovation Labs LLP, Chattanathan D, Founder Arya.ag, Samit Shetty, Founder of Chaitanya Microfinance and Former CEO of Navi Finserv, Subramanya S V, Founder of Fisdom, and Vinay Bhat led group of techie angels.
            </p>
            <a className='news-link' href='https://www.business-standard.com/content/press-releases-ani/angel-investors-fund-seed-round-in-navanc-for-assessing-property-creditworthiness-122062300635_1.html'>
                Read Article
            </a>
        </div>
    </div>
];
const delay = 5000;

const SlideShow = () => {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === news.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <>
        <h1 className='n-in-news'>Navanc in the News!</h1>
        <div className="slideshow">
            <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
            >
                {news.map((news, index) => {
                    return (
                        <div
                            className="slide"
                            key={index}
                        >    
                            {news}
                        </div>
                    )
                })}
            </div>

            <div className="slideshowDots">
                {news.map((_, idx) => (
                    <div
                        key={idx}
                        className={`slideshowDot${index === idx ? " active" : ""}`}
                        onClick={() => {
                        setIndex(idx);
                        }}
                    >
                    </div>
                ))}
            </div>
        </div>
        <hr></hr>
    </>
  );
}

export default SlideShow