import NavBar from 'src/components/navbar.js'
import Footer from 'src/components/footer'
import './nlite-product-page.css'
import BankSignup from 'src/components/landing_pages/bank-signup'
import nlite_graphic from "src/assets/n-lite.jpg";

const NliteProductPage = () => {
    return (
        <>
            <NavBar />
            <div className="container">
                <div className="graphics-container">
                    <img src={nlite_graphic} alt="nlite" />
                </div>
                <div className="nLite-first-section">
                    <div>
                        n-Lite is an AI-driven desktop valuation tool that enhances customer origination for lending institutions. Given its
                        minimal input and insightful output, it assists financial institutions to build sales pipelines more quickly with better quality.
                    </div>
                </div>
            </div>
            <BankSignup />
            <Footer />
        </>
    )
}

export default NliteProductPage