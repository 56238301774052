import './newsletter.css'
import validator from 'validator'

const Newsletter = () => {
    /*
    const addToEmailList = () =>{
        const listKey = "3ze301781d9955b8217510321085bdb8af9ed693e58f14d3cba5e75163c5aaac1f"
        const email = document.getElementsByClassName("newsletter-email")[0].value
        if (validator.isEmail(email)) {
            fetch(
                `https://campaigns.zoho.com/api/v1.1/json/listsubscribe?`
                +`resfmt=JSON&`
                +`listkey=`+listKey+`&`
                +`contactinfo=`
                +`%7B`
                    +`Contact+Email%3A`+email+`%2C`
                +`%7D`
            )
                .then(Response => Response.json())
                .then(data => console.log(data))
        } else {
            window.prompt("Enter a valid Email to subscribe to our newsletter")
        }
    }
    */
    return(
        <div className="newsletter-block">
            <div className="newsletter-signup">
                <h className="nl-signup-heading">
                    Signup to our Newsletter
                </h>
                <div className="newsletter-form">
                    <a
                        className="newsletter-submit"
                        href="https://zcmp.in/rqso"
                        //onClick={addToEmailList}
                    >
                        Join Now
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Newsletter