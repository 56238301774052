import { useNavigate } from "react-router-dom"
import './navicon-dropdown.css'

const NaviconDropdownItem = (props) => {
    const navigate = useNavigate();
    const onClickNaviconDropdownItem = () => {
        navigate(props.link);
    }
    return(
        <div className="navicon-dropdown-item" onClick={() => onClickNaviconDropdownItem()}>
            {props.label}
        </div>
    )
}

const NaviconDropdown = (props) => {
    const items = props.drop_down_items.map((item) => {
        return <NaviconDropdownItem label={item[0]} link={item[1]}></NaviconDropdownItem>
    })
    return(
        <div className="navicon-dropdown">
            <span>{props.label}</span>
            <div>{items}</div>
        </div>
    )
}

export default NaviconDropdown