import React, { useState } from 'react';

import 'src/components/styles/now-vs-navanc.css';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import navanc_logo from 'src/assets/navanc_logo.png'

const VsRow = (props) => {
    var [showExplanation, setShowExplanation] = useState(false);
    var arrow;
    var explanation = <div 
        className={
            `vs-row-explanation ${showExplanation? 'vs-row-explanation-visible':'vs-row-explanation-invisible'}`
        }
    >
        {props.explanation}
    </div>
    const onClickVsRowFn = () => {
        setShowExplanation(!showExplanation);
    }
    const downarrow = <ArrowDropDownIcon fontSize='inherit' className='dropdown-arrow'/>;
    const rightarrow = <ArrowRightIcon fontSize='inherit' className='dropdown-arrow' />
    arrow = showExplanation? downarrow: rightarrow;
    arrow = <div className='dropdown-arrow-container'> {arrow} </div>
    return (
        <div className='vs-row' onClick={() => onClickVsRowFn()}>
            <div className='vs-row-subheading'>
                {arrow} <div>{props.subheading}</div>
            </div>
            <div className='vs-row-explanation-container'>
                {explanation}
            </div>
        </div>
    )
}

const NowVsNavanc = () => {
    return(
        <div className='now-vs-navanc'>
            <div className="now-style vs-tabular-column">
                <div className="vs-heading">
                    Giving Property-Backed Loans Now
                </div>
                <div className="vs-row-containers first-vs-row-container">
                    <VsRow
                        subheading='Very Subjective'
                        explanation='Outcomes of the analysis can depend only on the particular lawyer, valuer and the banker with almost no oversight'
                    ></VsRow>
                    <VsRow
                        subheading='Takes Longer'
                        explanation='Bank officials, Lawyers, and Valuers examine properties physically and scrutinize hard copies of documents. The back and forth movement of files make it longer TAT.'
                    ></VsRow>
                    <VsRow
                        subheading='Data Seldom Verified'
                        explanation='Verification only through impaneled lawyer and valuers'
                    ></VsRow>
                    <VsRow
                        subheading='Subjective hand-written reports'
                        explanation='Reports are drafted manually'
                    ></VsRow>
                </div>
            </div>
            <div className="navanc-style vs-tabular-column">
                <div className="vs-heading">
                    Giving Property Backed Loans with <img className="vs-heading-navanc-logo" src={navanc_logo} />
                </div>
                <div className="vs-row-containers">
                    <VsRow
                        subheading='Standardized by Algorithms'
                        explanation='A carefully crafted algorithm based on the experience of bankers.'
                    ></VsRow>
                    <VsRow
                        subheading='Get a score in Minutes'
                        explanation='India’s first-ever comprehensive property credit worthiness score'
                    ></VsRow>
                    <VsRow
                        subheading='Data Verified against Government and Geo records'
                        explanation='Primary data collected thru Lawyers and Valuers is cross-verified thru secondary sources of info'
                    ></VsRow>
                    <VsRow
                        subheading='Standarized PDF report'
                        explanation='Standard digital reports within a few minutes of property search '
                    ></VsRow>
                </div>
            </div>
        </div>
    )
}

export default NowVsNavanc