import NavBar from 'src/components/navbar.js'
import Footer from 'src/components/footer'
import './valle-product-page.css'
import ValuerLawyerSignup from 'src/components/landing_pages/valuer-lawyer-signup'
import valle_graphics from 'src/assets/valle.jpg'
const ValleProductPage = () => {
    return (
        <>
            <NavBar />
            <div className="container">
                <div className="graphics-container">
                    <img src={valle_graphics} alt="valle" />
                </div>
                <div className="valle-first-section">
                    <div>
                        Valle is a platform that curates a selection of lawyers and valuers.
                        We have a wide network of lawyers and valuers across all geographic tiers.
                        This reduces a lot of physical movement of documents and also reduces the turnaround time for loan journeys for both customers and financial institutions.
                        Service Providers (Lawyers and Valuers) benefit from our workflow module, and their business volumes increase exponentially.
                    </div>
                </div>
            </div>
            {/* <div className="valle-first-section">
                <div>
                Valle is a platform that curates a selection of lawyers and valuers. 
                We have a wide network of lawyers and valuers across all geographic tiers. 
                This reduces a lot of physical movement of documents and also reduces the turnaround time for loan journeys for both customers and financial institutions. 
                Service Providers (Lawyers and Valuers) benefit from our workflow module, and their business volumes increase exponentially.
                </div>
            </div> */}
            <ValuerLawyerSignup />
            <Footer />
        </>
    )
}

export default ValleProductPage